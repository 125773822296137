import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';

export const readSetRetur = {
  data() {
    return {
      filter: {
        search: null,
        is_completed: null,
      },
      detailModal: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'returs/getLoading',
      getOfficeCategoryByName: 'office_categories/getOfficeCategoryByName',
      getReturs: 'returs/getReturs',
    }),
  },
  methods: {
    ...mapActions({
      fetchReturs: 'returs/fetchReturs',
      fetchRetur: 'returs/fetchRetur',
    }),
    onFilter() {
      this.loadReturs();
    },
    onSearch: debounce(function () {
      this.loadReturs();
    }, 300),
    onPageChange(page) {
      this.loadReturs({
        'page[number]': page,
      });
    },
    async onDetail(returShipment) {
      if (returShipment.attributes.is_completed) {
        const res = await this.fetchRetur({
          id: returShipment.id,
          include: 'origin-office,details',
          'fields[returs]':
            'origin_code,origin-office,details,updatedAt,total_price,retur_status,is_completed,is_received,is_using_stocks',
          'fields[offices]': 'code,name,office_type',
          'fields[retur-details]':
            'product_code,product_name,product_qty,total_price,expired_count,expired_price,damaged_count,damaged_price,unsuitable_for_sale_count,unsuitable_for_sale_price,suitable_for_sale_count,suitable_for_sale_price',
        });

        if (res) {
          this.detailModal.visible = true;
        }
      } else {
        this.$router.push({
          name: this.detailRouteName,
          params: {
            id: returShipment.id,
          },
        });
      }
    },
    loadReturs(params = {}) {
      const originCategory = this.getOfficeCategoryByName(
        this.originOfficeCategoryName
      );
      const pusatCategory = this.getOfficeCategoryByName('Pusat');

      this.fetchReturs({
        params: {
          'filter[origin_office_category_id]': originCategory.id,
          'filter[destination_office_category_id]': pusatCategory.id,
          'filter[is_received]': true,
          'filter[is_completed]': this.filter.is_completed,
          'filter[search]': this.filter.search,
          'page[size]': 5,
          'fields[returs]':
            'origin-warehouse,origin_code,total_price,updatedAt,origin-office,retur_status,is_completed',
          'fields[offices]': 'code',
          'fields[warehouses]': 'code',
          sort: '-createdAt',
          include: 'origin-office,origin-warehouse',
          ...params,
        },
      });
    },
  },
  created() {
    this.loadReturs();
  },
};

<template>
  <div class="">
    <h2 class="mb-4 text-2xl font-bold">Daftar Promo Pada Faktur Ini</h2>
    <ul>
      <li
        @click="toggleActive(index)"
        v-for="(voucher, index) in getAll['product-promos'].data"
        :key="index"
        class="mb-4 flex cursor-pointer items-center rounded-md border p-4 hover:bg-gray-50"
        :class="{
          'border-green-500 bg-green-50': voucher.isActive,
        }"
      >
        <div class="mb-2 w-full">
          <div>
            <strong class="text-lg">{{ voucher.attributes.name }}</strong> -
            <span class="text-gray-600">{{
              voucher.attributes.description
            }}</span>
          </div>
          <div
            v-if="voucher.attributes.finish_at"
            class="text-sm text-gray-500"
          >
            Expiry Date: {{ voucher.attributes.finish_at }}
          </div>
        </div>
        <Icon
          v-if="voucher.isActive"
          icon="heroicons:check-circle-20-solid"
          class="h-10 w-10 text-green-600"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      vouchers: [
        {
          code: 'VOUCHER1',
          description: '10% off on all items',
          discount: '10%',
          expiryDate: '2024-12-31',
          isActive: false,
        },
        {
          code: 'VOUCHER2',
          description: '$5 off on your next purchase',
          discount: '$5',
          expiryDate: '2025-06-30',
          isActive: false,
        },
        {
          code: 'VOUCHER3',
          description: 'Free shipping on orders over $50',
          discount: 'Free',
          expiryDate: null,
          isActive: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAll: 'main/getAll',
    }),
  },
  methods: {
    ...mapActions({
      fetchAll: 'main/fetchAll',
    }),
    redeemVoucher(voucher) {
      // Implement your redemption logic here
      console.log('Redeem voucher:', voucher.code);
    },
    toggleActive(index) {
      this.vouchers[index].isActive = !this.vouchers[index].isActive;
    },
  },
  created() {
    this.fetchAll({
      schema: 'product-promos',
    });
  },
};
</script>

<style scoped>
/* Add your custom CSS styles here */
</style>

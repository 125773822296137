<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-button :to="{ name: 'penjualan.topup.deposit-sc.tambah' }"
          >Topup Deposit SC</base-button
        >
      </div>
      <div>
        <datatable
          :columns="columns"
          :data="balanceTrxes.data"
          :scroll-x="false"
          :total="balanceTrxes.meta.page.total"
          :perPage="balanceTrxes.meta.page.perPage"
          :currentPage="balanceTrxes.meta.page.currentPage"
          @pagechanged="onPageChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="(balanceTrx, index) in balanceTrxes.data"
              :key="balanceTrx.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'bg-white hover:bg-green-100',
              ]"
            >
              <td :class="[classes.td]">
                <span class="font-bold text-gray-900">{{
                  getSingleIncluded(
                    balanceTrxes,
                    balanceTrx.relationships.office.data.id
                  ).attributes.code
                }}</span>
              </td>
              <td :class="classes.td">
                {{ balanceTrx.attributes.createdAt | formatDate }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ balanceTrx.attributes.deposit_amount | toCurrency }}
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="balanceTrx.attributes.is_approved ? 'green' : 'red'"
                >
                  {{
                    balanceTrx.attributes.is_approved
                      ? 'Valid'
                      : 'Belum Valid'
                  }}
                </base-badge>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>
    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      balanceTrxes: {
        data: [],
        meta: {
          page: {
            total: 0,
          },
        },
      },
      loadingBalanceTrxes: false
    };
  },
  computed: {
    columns() {
      return [
        { id: 'office_code', name: 'Kode Stockist Center' },
        { id: 'createdAt', name: 'Tanggal Top Up' },
        { id: 'amount', name: 'Jumlah Deposit', theadClass: 'text-right' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
    loading() {
      return this.loadingBalanceTrxes;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadBalanceTrxes(params = {}) {
      this.loadingBalanceTrxes = true;

      const [res, error] = await this.request('/api/v1/balance-trxes', {
        params: {
          'page[size]': 5,
          'filter[trx_type]': 'topup_deposit_balance',
          include: 'office',
          'fields[balance-trxes]':
            'office,createdAt,deposit_amount,is_approved',
          'fields[offices]': 'code',
          ...params,
        },
      });

      if (!error) {
        this.balanceTrxes = res;
      }

      this.loadingBalanceTrxes = false;
    },
    onPageChanged(page) {
      this.loadBalanceTrxes({
        'page[number]': page,
      });
    },
  },
  created() {
    this.loadBalanceTrxes();
  },
};
</script>

<template>
  <div class="mx-2 py-2 sm:px-6">
    <div v-if="isPageLoaded" class="space-y-5">
      <retur-set-price-header />

      <retur-set-price-form v-model="returDetailPrices" />

      <retur-set-price-balance-form v-model="balanceType" />

      <retur-set-price-action
        :data="{
          details: returDetailPrices,
          balanceType,
        }"
        :back-route="{
          name: 'validasi-saldo-retur.retur-dari-stockist-center',
        }"
        with-balance-type
        @validated="onValidated"
      />
    </div>

    <loading v-if="isLoading" />
  </div>
</template>

<script>
import ReturSetPriceHeader from '@/components/retur/retur-set-price-header.vue';
import ReturSetPriceForm from '@/components/retur/retur-set-price-form.vue';
import ReturSetPriceBalanceForm from '@/components/retur/retur-set-price-balance-form.vue';
import ReturSetPriceAction from '@/components/retur/retur-set-price-action.vue';
import { setPriceRetur } from '@/mixins/retur/set-price-retur.mixin';

export default {
  mixins: [setPriceRetur],
  components: {
    ReturSetPriceHeader,
    ReturSetPriceForm,
    ReturSetPriceAction,
    ReturSetPriceBalanceForm,
  },
  data() {
    return {
      indexRoute: {
        name: 'validasi-saldo-retur.retur-dari-stockist-center',
      }
    }
  },
};
</script>

<template>
  <base-modal :showing="visible" size="max-w-5xl" v-on:close="visible = false">
    <form v-on:submit.prevent="handleSubmitForm">
      <div class="mb-6">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Tambah Metode Pembayaran
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Tambah Metode Pembayaran
        </p>
      </div>
      <div class="space-y-4 border-t border-gray-200 pt-6">
        <div class="w-full border-b pb-6">
          <dt class="text-xs text-gray-700">Total Penjualan</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ getOrder.data.attributes.final_price | toCurrency }}
          </dd>
        </div>
        <div class="flex gap-x-4" v-for="(item, key) in payload" :key="key">
          <base-input label="Metode Pembayaran" inset with-label fullwidth>
            <base-select
              :options="paymentMethodOptions"
              inset
              v-model="item.method"
            />
          </base-input>
          <base-input
            label="Pilih Bank"
            inset
            with-label
            fullwidth
            v-if="isPrevFieldNotNull(item, 'method') && isUsingBank(item)"
          >
            <base-select
              :options="bankOptions"
              inset
              v-model="item.officeBankId"
            />
          </base-input>
          <base-input
            label="Tanggal Transfer"
            type="date"
            inset
            with-label
            fullwidth
            v-model="item.transferDate"
            v-if="isPrevFieldNotNull(item, 'officeBankId') && isUsingBank(item)"
          />
          <base-input
            class="relative"
            label="Nominal Pembayaran"
            placeholder="Nominal Pembayaran"
            inset
            with-label
            fullwidth
            v-if="
              isPrevFieldNotNull(
                item,
                isUsingBank(item) ? 'transferDate' : 'method'
              )
            "
          >
            <input
              type="text"
              class="focus:outline-none w-full border-0 p-0 pt-2 pb-0 text-sm text-gray-900 placeholder-gray-200 focus:ring-0"
              placeholder="Nominal Pembayaran"
              @input="(event) => onChangeAmount(event, key)"
            />
            <button
              @click="handleRemoveMethod(key)"
              type="button"
              class="focus:outline-none absolute inset-y-0 -top-2 -right-2 flex h-5 w-5 items-center rounded-full border border-transparent bg-red-300 p-1 text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </base-input>
        </div>
        <div class="flex justify-end gap-x-2">
          <base-button type="button" color="white" v-on:click="handleNewMethod"
            >Tambah Metode</base-button
          >
          <base-button type="submit" :disabled="isSubmitDisabled"
            >Simpan</base-button
          >
        </div>
      </div>
    </form>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';

export default {
  name: 'OrderCreatePaymentModal',
  components: { BaseModal, BaseInput, BaseSelect, BaseButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderId: null,
  },
  emits: ['input', 'success'],
  data: function () {
    return {
      payload: [
        {
          method: null,
          officeBankId: null,
          transferDate: null,
          amount: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getBanksByOffice: 'banks/getBanksByOffice',
      getOrder: 'orders/getOrder',
    }),
    visible: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
    officeBalance: function () {
      if (!this.getOrder.included.length) {
        return 0;
      }

      return this.getOrder.included.find(
        (included) => included.type === 'offices'
      ).attributes.balance;
    },
    isSubmitDisabled: function () {
      return (
        this.payload.length < 1 || this.payload.some((item) => !item.amount)
      );
    },
    paymentMethodOptions: function () {
      return [
        {
          key: null,
          label: 'Metode Pembayaran',
          value: null,
        },
        ...['Cash', 'Bank'].map((item) => ({
          key: item,
          label: item,
          value: this.getBanksByOffice.data?.find(
            (bank) => bank.attributes.office_bank_type === item.toLowerCase()
          )?.id,
        })),
        {
          key: null,
          label: `Saldo Stockist (${this.officeBalance})`,
          value: 'stockist',
        },
      ];
    },
    bankOptions: function () {
      return [
        {
          key: null,
          label: 'Pilih Bank',
          value: null,
        },
        ...(this.getBanksByOffice.data
          ? this.getBanksByOffice.data
              .filter((bank) => bank.attributes.office_bank_type === 'bank')
              .map((bank) => ({
                key: bank.id,
                label: bank.attributes.bank_name,
                value: bank.id,
              }))
          : []),
      ];
    },
  },
  watch: {
    visible: function (value) {
      this.clearPayload();

      if (value) {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
        });
        this.fetchOrder({
          id: this.orderId,
          include: 'destination-office,payments',
          fields: {
            orders: 'destination-office,payments,final_price',
            offices: 'balance',
            payments: 'code',
          },
        });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchOrder: 'orders/fetchOrder',
      createPayment: 'payments/createPayment',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
    }),
    isPrevFieldNotNull: function (item, key) {
      return item[key] !== null;
    },
    isUsingBank: function (item) {
      const officeBank = this.getBanksByOffice.data?.find(
        (bank) => bank.id === item.method
      );

      return officeBank && officeBank.attributes.office_bank_type === 'bank';
    },
    clearPayload: function () {
      this.payload = [
        {
          method: null,
          officeBankId: null,
          transferDate: null,
          amount: null,
        },
      ];
    },
    getOrderPayment: async function () {
      const orderPayment = this.getOrder.included.find(
        (included) => included.type === 'payments'
      );

      if (orderPayment) {
        return orderPayment;
      }

      const res = await this.createPayment({
        data: {
          type: 'payments',
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.orderId,
              },
            },
          },
        },
      });

      return res.data.data;
    },
    handleNewMethod: function () {
      this.payload.push({
        method: null,
        officeBankId: null,
        transferDate: null,
        amount: null,
      });
    },
    handleRemoveMethod: function (index) {
      this.payload.splice(index, 1);
    },
    handleSubmitForm: async function () {
      const payment = await this.getOrderPayment();

      // Create Payment Methods
      const batchRequests = this.payload.map((method) => {
        const officeBank = this.getBanksByOffice.data.find(
          (officeBank) => officeBank.id === method.method
        );

        return this.createPaymentMethod({
          paymentId: payment.id,
          officeBankId: officeBank?.id,
          attributes: {
            payment_amount: method.amount ? parseInt(method.amount) : 0,
            transfer_date: method.transferDate
              ? dayjs(method.transferDate).format()
              : null,
            ...(method.method === 'stockist'
              ? { balance_used: 'stockist' }
              : {}),
          },
        });
      });

      await Promise.all(batchRequests);

      this.$emit('success', this.orderId);
    },
    onChangeAmount(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.payload[index].amount = value;

      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
  },
};
</script>

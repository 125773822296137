<template>
  <div class="relative">
    <base-input
      inset
      with-label
      label="Kode Stockist Center"
      type="text"
      placeholder="SC1"
      v-model="office.code"
      @change="onSearchOffice"
    />
    <button
      type="button"
      class="absolute right-4 top-6 cursor-pointer text-gray-400"
      @click="onOpenOfficeModal"
    >
      <Icon class="h-5 w-5" icon="heroicons:magnifying-glass-circle-20-solid" />
    </button>

    <office-table-modal
      :visible="visibleOfficeModal"
      @close="visibleOfficeModal = false"
      @click-office="onChangeOffice"
    />

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import OfficeTableModal from './office-table-modal.vue';

export default {
  components: { OfficeTableModal },
  mixins: [requestMixin],
  props: {
    value: Object,
  },
  emits: ['input'],
  data() {
    return {
      loading: false,
      visibleOfficeModal: false,
    };
  },
  computed: {
    office: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onOpenOfficeModal() {
      this.visibleOfficeModal = true;
    },
    onChangeOffice(office) {
      this.office.id = office.attributes.office_id;
      this.office.code = office.attributes.code;
      this.office.originalCode = office.attributes.code;
      this.office.name = office.attributes.name;

      this.visibleOfficeModal = false;
    },
    async onSearchOffice() {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/offices', {
        params: {
          'filter[office_category_id]': 3,
          'filter[code]': this.office.code,
          'fields[simple-offices]': 'code,name,office_id',
        },
      });

      if (err || !res.data.length) {
        this.office.code = this.office.originalCode;
      } else {
        const office = res.data[0];

        this.office.id = office.attributes.office_id;
        this.office.code = office.attributes.code;
        this.office.originalCode = office.attributes.code;
        this.office.name = office.attributes.name;
      }

      this.loading = false;
    },
  },
};
</script>

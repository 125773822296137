<template>
  <div class="relative" v-click-outside="handleClickOutside">
    <div
      class="block flex h-10 items-center justify-between rounded-md border border-gray-300 bg-white px-3 py-0 shadow focus:border-green-500 focus:ring-green-500 sm:text-sm"
      v-on:click="handleToggleVisible"
    >
      <span>
        {{
          items.find((it) => it.value == selected?.[0])?.label ?? 'Pilih Filter'
        }}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <transition name="ease-out-overlay" v-if="visible">
      <div
        class="absolute top-12 left-0 z-10 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
      >
        <ul>
          <li
            v-for="item in items"
            :key="item.key"
            class="relative flex cursor-default select-none items-center justify-between py-2 px-3 text-gray-900 hover:bg-gray-100"
            v-on:click="handleClickItem(item.value)"
          >
            <span>{{ item.label }}</span>
            <svg
              v-if="isItemSelected(item.value)"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseSelectRadio',
  props: {
    value: null,
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['input', 'change'],
  data: function () {
    return {
      visible: false,
    };
  },
  computed: {
    selected: {
      get: function () {
        return this.value ?? [];
      },
      set: function (value) {
        this.$emit('input', value);
        this.$emit('change');
      },
    },
  },
  methods: {
    isItemSelected: function (item) {
      return this.selected.includes(item);
    },
    handleToggleVisible: function () {
      this.visible = !this.visible;
    },
    handleClickItem: function (item) {
      this.selected = [item];
    },
    handleClickOutside: function () {
      this.visible = false;
    },
  },
};
</script>

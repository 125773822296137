import { mapGetters, mapActions } from 'vuex';

export const setPriceRetur = {
  data() {
    return {
      returDetailPrices: [],
      balanceType: null,
      isPageLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isReturLoading: 'returs/getLoading',
      isReturDetailsLoading: 'retur_details/getLoading',
      isProductPricesLoading: 'product_prices/getLoading',
      getReturDetails: 'retur_details/getReturDetails',
    }),
    isLoading() {
      return (
        this.isReturLoading ||
        this.isReturDetailsLoading ||
        this.isProductPricesLoading
      );
    },
  },
  methods: {
    ...mapActions({
      fetchRetur: 'returs/fetchRetur',
      fetchReturDetailsByRetur: 'retur_details/fetchReturDetailsByRetur',
    }),
    onValidated() {
      this.$router.push(this.indexRoute);
    },
    setReturDetailPrices() {
      this.returDetailPrices = this.getReturDetails.data.map((detail) => ({
        id: detail.id,
        product_prices: [],
        product_code: detail.attributes.product_code,
        product_name: detail.attributes.product_name,
        product_qty: detail.attributes.product_qty,
        total_price: detail.attributes.total_price,
        expired_count: detail.attributes.expired_count,
        damaged_count: detail.attributes.damaged_count,
        suitable_for_sale_count: detail.attributes.suitable_for_sale_count,
        unsuitable_for_sale_count: detail.attributes.unsuitable_for_sale_count,
        expired_price: null,
        damaged_price: null,
        suitable_for_sale_price: null,
        unsuitable_for_sale_price: null,
        expired_total_price: 0,
        damaged_total_price: 0,
        suitable_for_sale_total_price: 0,
        unsuitable_for_sale_total_price: 0,
        expired_product_price_id: null,
        damaged_product_price_id: null,
        suitable_for_sale_product_price_id: null,
        unsuitable_for_sale_product_price_id: null,
      }));
    },
    async loadReturDetailPriceProductPrice(index) {
      const res = await this.fetchProductPrices({
        productId:
          this.getReturDetails.data[index].relationships.product.data.id,
        params: {
          'fields[product-prices]': 'price,buyer-type,area',
        },
      });

      if (res) {
        this.returDetailPrices[index].product_prices = res.data.data.map(
          (productPrice) => ({
            id: productPrice.id,
            price: productPrice.attributes.price,
            buyerType: productPrice.attributes['buyer-type'],
            area: productPrice.attributes.area,
          })
        );
      }
    },
    async loadRetur() {
      const returs = await this.fetchRetur({
        id: this.$route.params.id,
        include: 'origin-office,origin-warehouse',
        'fields[returs]':
          'origin-office,origin-warehouse,origin_code,updatedAt,retur_status',
        'fields[offices]': 'code',
        'fields[warehouses]': 'code',
      });

      if (returs) {
        this.isPageLoaded = true;

        const returDetails = await this.fetchReturDetailsByRetur({
          returId: this.$route.params.id,
          params: {
            include: 'product',
            'fields[retur-details]':
              'product,product_code,product_name,product_qty,total_price,expired_count,damaged_count,suitable_for_sale_count,unsuitable_for_sale_count',
            'fields[products]': 'code',
          },
        });

        if (returDetails) {
          this.setReturDetailPrices();
        }
      }
    },
  },
  created() {
    this.loadRetur();
  },
};

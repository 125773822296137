<template>
  <BaseCard
    v-if="
      value.faktur.nama_pemilik !== null &&
      value.faktur.id_gudang &&
      value.faktur.wilayah_harga !== null
    "
  >
    <div class="flex flex-col">
      <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                for="invoice"
                class="block text-sm font-medium text-gray-700"
              >
                Catatan
              </label>
              <textarea
                v-model="value.faktur.notes"
                id="about"
                name="about"
                rows="3"
                class="field-inset-default relative block w-full max-w-lg rounded-md border border-gray-300 shadow-sm sm:text-sm"
              ></textarea>
            </div>
            <div class="flex w-full justify-end sm:col-span-3">
              <fieldset class="flex mt-4 flex-col gap-y-4">
                <div class="flex">
                  <div class="flex h-5 items-center">
                    <input
                      id="cetak-faktur"
                      aria-describedby="cetak-faktur"
                      name="cetak-faktur"
                      type="checkbox"
                      value="true"
                      v-model="options.cetak"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="cetak-faktur" class="font-medium text-gray-700"
                      >Cetak Faktur</label
                    >
                    <p id="cetak-faktur" class="text-gray-500">
                      Simpan dan cetak faktur secara langsung
                    </p>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex h-5 items-center">
                    <input
                      id="download-faktur"
                      aria-describedby="cetak-faktur"
                      name="cetak-faktur"
                      type="checkbox"
                      value="true"
                      v-model="options.download"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="download-faktur"
                      class="font-medium text-gray-700"
                      >Download Faktur</label
                    >
                    <p id="download-faktur" class="text-gray-500">
                      Simpan dan download faktur ke komputer anda
                    </p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/base/BaseCard.vue';

export default {
  name: 'OrderNotesForm',
  components: {
    BaseCard,
  },
  props: {
    value: {
      type: Object,
    },
    options: Object,
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },

  methods: {
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>

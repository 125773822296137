<template>
  <div class="py-2 sm:px-6 mx-2">
    <div class="space-y-5">
      <ReturCreateForm
        :retur="retur"
        origin-office-type="kantor_pelayanan"
        @change-origin-office="onChangeOriginOffice"
      />

      <template
        v-if="canAddReturDetails"
      >
        <ReturDetailForm
          v-model="returDetails"
          :retur="retur"
          @retur-created="onReturCreated"
        />
      </template>

      <div>
        <div class="flex justify-end gap-x-2">
          <base-button
            v-if="!retur.attributes.is_valid_for_shipment"
            id="simpan"
            :disabled="!canValidate"
            @click="onConfirm"
          >
            Siap Validasi
          </base-button>

          <router-link
            :to="{
              name: 'index.retur.pelayanan',
            }"
          >
            <base-button color="white">Selesai</base-button>
          </router-link>
        </div>
      </div>

      <ConfirmModal
        :visible="confirmModal.visible"
        @close="confirmModal.visible = false"
        @saving="onSave"
      />
    </div>

    <loading
      v-if="
        isLoadingStocks ||
        isLoadingSimpleOffices ||
        isLoadingOffices ||
        isLoadingReturs ||
        isLoadingWarehouses ||
        isLoadingReturDetails
      "
    ></loading>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import BaseButton from '@/components/base/BaseButton.vue';
import ConfirmModal from '@/components/penjualan/create/confirm-modal.vue';
import ReturDetailForm from '@/components/retur/retur-details-form.vue';
import ReturCreateForm from '@/components/retur/retur-create-form.vue';

export default {
  name: 'TambahRetur',
  components: {
    ConfirmModal,
    ReturDetailForm,
    BaseButton,
    ReturCreateForm,
  },
  data() {
    return {
      retur: {
        id: null,
        originOffice: {
          id: null,
          code: null,
          name: null
        },
        originWarehouse: {
          id: null,
          code: null
        },
        originArea: {
          code: null
        },
        attributes: {
          createdAt: new Date,
          is_using_stocks: true,
          shipment_type: 'pickup',
          is_valid_for_shipment: false
        },
      },
      returDetails: [
        {
          id: null,
          product_id: null,
          product_code: null,
          original_product_code: null,
          product_name: null,
          current_stock: null,
          product_price: null,
          product_qty: null,
          original_product_qty: null,
          product_weight: null,
          point_value: null,
          bonus_value: null
        }
      ],
      confirmModal: {
        visible: false
      }
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.setRetur(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getRetur: 'returs/getRetur',
      isLoadingSimpleOffices: 'simple_offices/getLoading',
      isLoadingOffices: 'offices/getLoading',
      isLoadingStocks: 'stocks/getLoading',
      isLoadingWarehouses: 'warehouses/getLoading',
      isLoadingReturs: 'returs/getLoading',
      isLoadingReturDetails: 'retur_details/getLoading',
    }),
    canAddReturDetails() {
      return !!this.retur.originOffice.id
    },
    canValidate() {
      return !!this.retur.originOffice.id
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchRetur: 'returs/fetchRetur',
      createRetur: 'returs/createRetur',
      validateRetur: 'returs/validateRetur',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice'
    }),
    onChangeOriginOffice(office) {
      this.retur.originOffice.id = office.id
      this.retur.originOffice.code = office.attributes.code
      this.retur.originOffice.name = office.attributes.name

      const warehouse = this.getSingleIncluded(this.getOffice, office.relationships['current-warehouse'].data.id)

      this.retur.originWarehouse.id = warehouse.id
      this.retur.originWarehouse.code = warehouse.attributes.code

      if (office.relationships['area'].data) {
        const area = this.getSingleIncluded(this.getOffice, office.relationships['area'].data.id)

        this.retur.originArea.code = area.attributes.code
      }
    },
    onReturCreated(retur) {
      this.$router.push(`/penjualan/retur/kantor-pelayanan/tambah-retur/${retur.id}`)

      this.retur.id = retur.id
    },
    onConfirm() {
      this.confirmModal.visible = true
    },
    async onSave() {
      if (this.retur.id) {
        this.onValidate(this.retur.id)
      } else {
        const payload = {
          data: {
            type: 'returs',
            attributes: {
              shipment_type: this.retur.attributes.shipment_type,
              is_using_stocks: this.retur.attributes.is_using_stocks
            },
            relationships: {
              'origin-office': {
                data: {
                  type: 'offices',
                  id: this.retur.originOffice.id,
                },
              },
            },
          },
        };

        const retur = await this.createRetur({
          payload,
        });

        if (retur) {
          this.onValidate(retur.data.data.id)
        }
      }
    },
    async onValidate(id) {
      const validated = await this.validateRetur({ id })

      if (validated) {
        this.$router.push({ name: 'index.retur.pelayanan' })
      }
    },
    async setRetur(id) {
      const res = await this.fetchRetur({
        id,
        'include': 'origin-office,origin-warehouse,retur-details',
        'fields[returs]': 'createdAt,updatedAt,is_using_stocks,is_valid_for_shipment,shipment_type,origin-office,origin-warehouse,retur-details',
        'fields[offices]': 'code,name',
        'fields[warehouses]': 'code,area_code',
        'fields[retur-details]': 'product_id,product_code,product_name,current_stock,product_qty,product_price,product_qty,product_weigth,product_point,product_bonus'
      })

      if (res.data.data) {
        const retur = res.data.data

        this.retur.id = retur.id
        this.retur.attributes.shipment_type = retur.attributes.shipment_type
        this.retur.attributes.is_using_stocks = retur.attributes.is_using_stocks
        this.retur.attributes.createdAt = retur.attributes.createdAt
        this.retur.attributes.is_valid_for_shipment = retur.attributes.is_valid_for_shipment

        const originOffice = this.getSingleIncluded(res.data, retur.relationships['origin-office'].data.id)
        const originWarehouse = this.getSingleIncluded(res.data, retur.relationships['origin-warehouse'].data.id)
        
        const returDetails = this.getIncludedByType(res.data, 'retur-details')

        this.retur.originOffice.id = originOffice.id
        this.retur.originOffice.code = originOffice.attributes.code
        this.retur.originOffice.name = originOffice.attributes.name

        this.retur.originWarehouse.id = originWarehouse.id
        this.retur.originWarehouse.code = originWarehouse.attributes.code

        this.retur.originArea.code = originWarehouse.attributes.area_code

        this.returDetails = returDetails.map(returDetail => ({
          id: returDetail.id,
          product_id: returDetail.attributes.product_id,
          product_code: returDetail.attributes.product_code,
          original_product_code: returDetail.attributes.product_code,
          product_name: returDetail.attributes.product_name,
          current_stock: returDetail.attributes.current_stock + returDetail.attributes.product_qty,
          product_price: returDetail.attributes.product_price,
          product_qty: returDetail.attributes.product_qty,
          original_product_qty: returDetail.attributes.product_qty,
          product_weight: returDetail.attributes.product_weight,
          point_value: returDetail.attributes.product_point,
          bonus_value: returDetail.attributes.product_bonus
        }))

        if (!retur.attributes.is_valid_for_shipment) {
          this.returDetails.push({
            id: null,
            product_id: null,
            product_code: null,
            original_product_code: null,
            product_name: null,
            current_stock: null,
            product_price: null,
            product_qty: null,
            original_product_qty: null,
            product_weight: null,
            point_value: null,
            bonus_value: null
          })
        }
      }
    }
  },
};
</script>

<template>
  <div class="mx-auto px-4 pb-12 sm:px-12 lg:px-24">
    <!-- Replace with your content -->
    <div>
      <h3 class="text-2xl font-semibold leading-6 text-gray-900">
        Promo Berjalan
      </h3>
      <div class="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div
          class="overflow-hidden rounded-lg bg-white shadow hover:bg-gray-50"
          v-for="item in getAll['product-bundles']?.data"
          :key="item.name"
        >
          <div class="relative p-4">
            <div class="flex items-center">
              <div class="w-0 flex-1">
                <div>
                  <div
                    class="flex items-center justify-between md:block lg:flex"
                  >
                    <div class="truncate text-sm font-medium text-gray-900">
                      {{ item.attributes.name }}
                    </div>
                  </div>
                  <div class="mt-2 text-xs">
                    <template
                      v-for="productRequirement in item.relationships[
                        'product-requirements'
                      ].data"
                    >
                      {{
                        getProductRequirementText(
                          getSingleIncluded(
                            getAll['product-bundles'],
                            productRequirement.id
                          )
                        )
                      }}
                    </template>
                    <br />
                    <template
                      v-for="productBonus in item.relationships[
                        'product-bonuses'
                      ].data"
                    >
                      {{
                        getProductBonusText(
                          getSingleIncluded(
                            getAll['product-bundles'],
                            productBonus.id
                          )
                        )
                      }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /End replace -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getAll: 'main/getAll',
    }),
  },
  methods: {
    ...mapActions({
      fetchAll: 'main/fetchAll',
    }),
    getProductRequirementText(resource) {
      return `Pembelian ${resource.attributes.min_qty} ${resource.attributes.product_name} (${resource.attributes.product_code})`;
    },
    getProductBonusText(resource) {
      return `Gratis ${resource.attributes.qty} ${resource.attributes.product_name} (${resource.attributes.product_code})`;
    },
  },
  created() {
    this.fetchAll({
      schema: 'product-bundles',
      params: {
        include: 'product-requirements,product-bonuses',
      },
    });
  },
};
</script>

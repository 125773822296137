<template>
  <div class="mx-2 py-2 sm:px-6">
    <base-card class="space-y-4">
      <div class="border-b border-gray-200 pb-5">
        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Validasi Saldo Retur dari Stockist Center
          </h3>
        </div>
      </div>

      <div class="flex flex-col space-y-4">
        <retur-set-price-table-filter
          v-model="filter"
          @filter="onFilter"
          @search="onSearch"
        />

        <retur-table @detail="onDetail" @page-change="onPageChange" />
      </div>
    </base-card>

    <retur-detail-modal
      :visible="detailModal.visible"
      @close="detailModal.visible = false"
    />

    <loading v-if="isLoading" />
  </div>
</template>

<script>
import ReturDetailModal from '@/components/retur/retur-detail-modal.vue';
import ReturTable from '@/components/retur/retur-table.vue';
import ReturSetPriceTableFilter from '@/components/retur/retur-set-price-table-filter.vue';
import { readSetRetur } from '@/mixins/retur/read-set-retur.mixin';

export default {
  mixins: [readSetRetur],
  components: {
    ReturDetailModal,
    ReturSetPriceTableFilter,
    ReturTable,
  },
  data() {
    return {
      detailRouteName:
        'validasi-saldo-retur.retur-dari-stockist-center.set-price',
      originOfficeCategoryName: 'Stockist Center',
    };
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 sm:px-6 mx-2"},[_c('div',{staticClass:"space-y-5"},[_c('ReturCreateForm',{attrs:{"retur":_vm.retur,"origin-office-type":"supplier"},on:{"change-origin-office":_vm.onChangeOriginOffice}}),(_vm.canAddReturDetails)?[_c('ReturDetailForm',{attrs:{"retur":_vm.retur},on:{"retur-created":_vm.onReturCreated},model:{value:(_vm.returDetails),callback:function ($$v) {_vm.returDetails=$$v},expression:"returDetails"}})]:_vm._e(),_c('div',[_c('div',{staticClass:"flex justify-end gap-x-2"},[(!_vm.retur.attributes.is_valid_for_shipment)?_c('base-button',{attrs:{"id":"simpan","disabled":!_vm.canValidate},on:{"click":_vm.onConfirm}},[_vm._v(" Siap Validasi ")]):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'index.retur.supplier',
          }}},[_c('base-button',{attrs:{"color":"white"}},[_vm._v("Selesai")])],1)],1)]),_c('ConfirmModal',{attrs:{"visible":_vm.confirmModal.visible},on:{"close":function($event){_vm.confirmModal.visible = false},"saving":_vm.onSave}})],2),(
      _vm.isLoadingStocks ||
      _vm.isLoadingSimpleOffices ||
      _vm.isLoadingOffices ||
      _vm.isLoadingReturs ||
      _vm.isLoadingWarehouses ||
      _vm.isLoadingReturDetails
    )?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <base-card class="space-y-4">
    <div class="flex items-center justify-between">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">Tipe Saldo</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Pilih total harga retur dimasukkan ke Saldo Restock atau Saldo
          Pembayaran
        </p>
      </div>
    </div>
    <div class="flex items-center space-x-8 border-t border-gray-200 pt-4">
      <div class="items-center text-sm text-gray-700">
        <input
          type="radio"
          name="balance_type"
          value="restock_balance"
          class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
          v-model="balanceType"
        />
        Saldo Restock
      </div>
      <div class="items-center text-sm text-gray-700">
        <input
          type="radio"
          name="balance_type"
          value="overpayment_balance"
          class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
          v-model="balanceType"
        />
        Saldo Pembayaran
      </div>
    </div>
  </base-card>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    value: String,
  },
  computed: {
    balanceType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<template>
  <base-card class="space-y-4">
    <div class="flex items-center justify-between">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Validasi Saldo Retur
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">Validasi Saldo Retur</p>
      </div>
      <div>
        <base-badge color="indigo">{{
          getRetur.data.attributes.retur_status
        }}</base-badge>
      </div>
    </div>
    <div class="border-t border-gray-200 pt-4">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Nomor Retur</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getRetur.data.attributes.origin_code }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">
            Nama Kantor / Gudang Kantor
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              getSingleIncluded(
                getRetur,
                getRetur.data.relationships['origin-office'].data.id
              ).attributes.code
            }}
            /
            {{
              getSingleIncluded(
                getRetur,
                getRetur.data.relationships['origin-warehouse'].data.id
              ).attributes.code
            }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ getRetur.data.attributes.updatedAt | formatDate }}
          </dd>
        </div>
      </dl>
    </div>
  </base-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getRetur: 'returs/getRetur',
    }),
  },
};
</script>

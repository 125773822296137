<template>
  <section id="laporan-penjualan-pusat" class="my-10 space-y-4 px-8">
    <!-- Filter -->
    <div class="flex items-center justify-end gap-x-2">
      <base-input label="Kantor Tujuan" with-label>
        <stockist-select-search
          v-model="filter.destination_warehouse"
          v-on:change="handleChangeFilter"
        />
      </base-input>
      <base-input
        type="date"
        label="Dari"
        :max="filter.to_date"
        with-label
        v-model="filter.from_date"
        v-on:change="handleChangeFilterDate"
      />
      <base-input
        type="date"
        label="Sampai"
        :min="filter.from_date"
        with-label
        v-model="filter.to_date"
        v-on:change="handleChangeFilterDate"
      />
      <base-input class="w-2/12" label="Tipe Stockist" with-label>
        <base-select-checkbox
          :items="destinationOptions"
          v-model="filter.destinations"
          v-on:change="handleChangeFilter"
        />
      </base-input>
      <button
        class="focus:outline-none inline-flex gap-2 self-end rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
        :disabled="isLoadingDailyReport"
        v-on:click="handleClickReport"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-5 w-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          ></path>
        </svg>
      </button>
    </div>

    <!-- Table -->
    <div class="inline-block min-w-full align-middle">
      <datatable
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        @pagechanged="handleChangePage"
      >
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Nomor Faktur
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Tanggal Faktur
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Stockist
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Belanja
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Gudang
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Keterangan
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <div v-if="isLoading" class="flex items-center text-center">
            <loading></loading>
          </div>
          <template v-if="orders.data.length">
            <tbody>
              <tr
                class="bg-white hover:bg-green-100"
                v-for="(order, index) in orders.data"
                :key="order.id"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ order.attributes.origin_code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ dayjs(order.attributes.createdAt).format('L') ?? '-' }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ order.attributes.destination_code }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ order.attributes.final_price | toCurrency }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ order.attributes.origin_warehouse_code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ order.attributes.notes ?? '-' }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="bg-white">
                <td colspan="2"></td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                >
                  Total Penjualan
                </td>
                <td
                  class="space-x-1 whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <span>
                    {{
                      Number(orders.jsonapi.meta?.total_final_price ?? 0)
                        | toCurrency
                    }}
                  </span>
                </td>
                <td
                  colspan="2"
                  class="space-x-1 whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  <span> dari </span>
                  <span>{{ orders.meta.page.total }}</span>
                  <span> Faktur</span>
                </td>
              </tr>
            </tbody>
          </template>
          <tbody v-else>
            <tr class="bg-white">
              <td
                colspan="6"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                Tidak ada data
              </td>
            </tr>
          </tbody>
        </template>
      </datatable>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelectCheckbox from '@/components/base/BaseSelectCheckbox.vue';
import Datatable from '@/components/base/Datatable.vue';
import Loading from '@/components/Loading.vue';
import debounce from 'debounce';
import dayjs from 'dayjs';
import StockistSelectSearch from '@/components/stockist/stockist-select-search.vue';
import { downloadFileUrl } from '@/services/utils.service';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'LaporanPenjualanHarian',
  components: {
    BaseInput,
    BaseSelectCheckbox,
    Datatable,
    Loading,
    StockistSelectSearch,
  },
  data: function () {
    return {
      filter: {
        from_date: dayjs().format('YYYY-MM-DD'),
        to_date: dayjs().format('YYYY-MM-DD'),
        destinations: ['STOCKIST', 'SCN', 'PRSH', 'KYN'],
        destination_warehouse: null,
      },
      pagination: {
        size: 5,
        number: 1,
      },
      isLoadingDailyReport: false,
      dailyReportProductChannelName: null
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
      getUser: 'auth/getUser',
    }),
    destinationOptions: function () {
      return [
        {
          key: 'stockist',
          label: 'Stockist',
          value: 'STOCKIST',
        },
        {
          key: 'scn',
          label: 'SCN',
          value: 'SCN',
        },
        {
          key: 'prsh',
          label: 'PRSH',
          value: 'PRSH',
        },
        {
          key: 'kyn',
          label: 'KYN',
          value: 'KYN',
        },
      ];
    },
    orders: function () {
      return this.getOrders;
    },
    fetchOrdersParams: function () {
      return {
        // sort: 'origin_code',
        'filter[order_status]': 'Selesai',
        'page[limit]': this.pagination.size,
        // 'page[number]': this.pagination.number,
        'filter[from_date]': this.filter.from_date,
        'filter[to_date]': this.filter.to_date,
        'filter[origin_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'filter[destination_warehouse_id]':
          this.filter.destination_warehouse?.relationships?.warehouses?.data[0]
            ?.id,
        // 'filter[stockist_type]': this.filter.destinations.join(','),
        showOrdersMeta: true,
        fields: {
          orders:
            'origin_code,destination_code,final_price,origin_warehouse_code,notes,createdAt',
        },
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      dailyReport: 'orders/dailyReport',
      createAlert: 'alert/createAlert',
    }),
    reload: function () {
      this.fetchOrders(this.fetchOrdersParams);
    },
    handleChangePage: function (page) {
      this.pagination.number = page;

      this.reload();
    },
    handleChangeFilter: function () {
      this.reload();
    },
    handleChangeFilterDebounce: debounce(function () {
      this.reload();
    }, 500),
    handleChangeFilterDate: function () {
      if (new Date(this.filter.from_date) > new Date(this.filter.to_date)) {
        this.filter.from_date = this.filter.to_date;
      }

      this.handleChangeFilterDebounce();
    },
    handleClickReport: function () {
      this.isLoadingDailyReport = true;

      this.dailyReport({
        action: 'download',
        fromDate: this.filter.from_date,
        toDate: this.filter.to_date,
        origin_warehouse_id:
          this.fetchOrdersParams['filter[origin_warehouse_id]'],
        destination_warehouse_id:
          this.fetchOrdersParams['filter[destination_warehouse_id]'],
        // stockistType: this.fetchOrdersParams['filter[stockist_type]'],
      });
    },
    listenToExportProductEvent() {
      this.dailyReportProductChannelName = `private-DailyReportOrder.${this.getUser.id}`

      const dailyReportProductChannel = this.$pusher.subscribe(this.dailyReportProductChannelName);

      dailyReportProductChannel.bind('DailyReportOrderStatusUpdated', (e) => {
        if (e.status === 'processing') {
          this.isLoadingDailyReport = true;
        } else if (e.status === 'failed') {
          this.isLoadingDailyReport = false;

          this.createAlert({ data: e.message, status: 'error' });
        } else if (e.status === 'finished') {
          this.isLoadingDailyReport = false;

          downloadFileUrl(e.file_url);
        }
      });
    },
    stopListenToExportProductEvent() {
      this.$pusher.unsubscribe(this.dailyReportProductChannelName);
    },
  },
  created: function () {
    this.fetchOrders(this.fetchOrdersParams);
    this.listenToExportProductEvent();
  },
  beforeRouteLeave(to, from, next) {
    this.stopListenToExportProductEvent();

    next();
  },
};
</script>

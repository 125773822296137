<template>
  <div class="mx-2 py-2 sm:px-6">
    <div class="space-y-5">
      <retur-create-form
        :retur="retur"
        origin-office-type="stockist"
        @change-origin-office="onChangeOriginOffice"
      />

      <template v-if="canAddReturDetails">
        <retur-detail-form
          v-model="returDetails"
          :retur="retur"
          @retur-created="onReturCreated"
        />
      </template>

      <div>
        <div class="flex justify-end">
          <retur-create-form-action
            :retur="retur"
            :retur-details="returDetails"
            :index-route="indexRoute"
            @confirmed="onConfirmed"
          />
        </div>
      </div>
    </div>

    <loading v-if="isLoading"></loading>
  </div>
</template>
<script>
import ReturDetailForm from '@/components/retur/retur-details-form.vue';
import ReturCreateForm from '@/components/retur/retur-create-form.vue';
import ReturCreateFormAction from '@/components/retur/retur-create-form-action.vue';
import { createRetur } from '@/mixins/retur/create-retur.mixin';

export default {
  name: 'TambahRetur',
  components: {
    ReturDetailForm,
    ReturCreateForm,
    ReturCreateFormAction,
  },
  mixins: [createRetur],
  data() {
    return {
      createFormRoutePath: '/penjualan/retur/stockist/tambah-retur',
      indexRoute: { name: 'index.retur.stockist' },
    };
  },
};
</script>

<template>
  <div class="mx-auto my-4 space-y-5 px-4 sm:px-4 lg:px-8">
    <loading v-if="loading.visible" :text="loading.text" />

    <OrderCreateForm
      :area="selectedArea"
      destination-office-type="stockist_center"
      origin-warehouse-type="pusat,kantor_pelayanan"
      selectable-destination-warehouse
      v-model="order"
      v-on:change-address="setupArea"
      v-on:change-order-shipment="setupArea"
      v-on:change-destination="setupArea"
      v-on:change-origin-warehouse="setupArea"
    />

    <template v-if="canAddProduct">
      <OrderDetailTable
        :create-order-payload="{
          order_type: 'sale',
          order_shipment: order.attributes.order_shipment,
          notes: order.attributes.notes,
          is_ppn_included: true,
          is_pre_order: false,
        }"
        :origin-warehouse="{
          id: order.relationships.originWarehouse.id,
        }"
        :destination-warehouse="{
          id: order.relationships.destinationWarehouse.id,
        }"
        :origin-office="{
          id: order.relationships.originOffice.id,
          code: order.relationships.originOffice.attributes.code,
        }"
        :destination-office="{
          id: order.relationships.destinationOffice.attributes.office_id,
          code: rawDestinationOfficeCode,
        }"
        :buyer-type="{
          id: order.relationships.buyerType.id,
          code: order.relationships.buyerType.attributes.code,
        }"
        :area="{
          id: order.relationships.area.id,
          code: order.relationships.area.attributes.code,
        }"
        :order-id="order.id"
        :min-grand-total="minOrderSpend"
        v-model="orderDetails"
        v-on:order-created="handleCreatedOrder"
        v-on:created="handleUpdatedOrderDetail"
        v-on:order-updated="handleUpdatedOrderDetail"
      />

      <OrderPromoList :order-details="orderDetailsForPromoList" :product-bundles="productBundles" @change-selected-bundles="onChangeSelectedBundles" @change-choosen-bonuses="onChangeChoosenBonuses" />

      <template v-if="orderDetails.length > 1">
        <div
          class="flex flex-col space-y-5 space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
        >
          <div class="border-b border-gray-200 pb-5">
            <h3 class="text-lg font-bold leading-6 text-gray-900">Saldo</h3>
            <p class="mt-2 max-w-4xl text-sm text-gray-500">
              Pilih saldo yang akan digunakan untuk melakukan pembayaran
            </p>
          </div>
          <fieldset class="">
            <div class="space-y-4">
              <div
                class="flex items-center justify-between"
                v-for="(method, index) in paymentMethods"
                :key="index"
              >
                <label
                  :for="method.balance_used"
                  class="block cursor-pointer text-sm font-medium text-gray-700"
                >
                  <input
                    :id="method.balance_used"
                    v-model="method.isUsed"
                    type="checkbox"
                    name="balance"
                    min="0"
                    class="mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  {{ balanceUsedTypes[method.balance_used].label }}
                  <span
                    >(
                    {{
                      balanceUsedTypes[method.balance_used].value | toCurrency
                    }}
                    )</span
                  >
                </label>
                <!-- Input -->
                <div v-if="method.isUsed" class="w-1/2">
                  <div class="field-inset-default relative">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Total Restock
                    </label>
                    <input
                      type="text"
                      name="offices"
                      id="offices"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="contoh: 100000"
                      @input="(e) => handleChangePaymentMethodAmount(e, index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </template>

      <OrderCreateActionForm
        :total-price="totalPrice"
        :min-order-spend="minOrderSpend"
        cancel-redirect="/penjualan/faktur-sc"
        v-on:process="handleProcessOrder"
      />

      <OrderDetailModal
        :visible="success"
        :order="getOrder.data"
        :editable="true"
        :deleteable="true"
        :validationIgnorePaymentStatus="true"
        :withPayments="false"
        with-bonus
        @close="handleClose"
        @edit="success = false"
        @refresh="handleClose"
      />
    </template>
  </div>
</template>

<script>
import OrderCreateForm from '@/components/order/create/OrderCreateForm.vue';
import OrderDetailTable from '@/components/order/create/OrderDetailTable.vue';
import OrderCreateActionForm from '@/components/order/create/OrderCreateActionForm.vue';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import { orderCreateMixin } from '@/mixins/order/order-create.mixin';
import { loadingMixin } from '@/mixins/loading/loading.mixin';
import { orderDetailsFormMixin } from '@/mixins/order/order-details-form.mixin';
import { mapActions, mapGetters } from 'vuex';
import { productBundleMixins } from '@/mixins/product-bundle/product-bundle.mixin';
import OrderPromoList from '@/components/promo/OrderPromoList.vue';

export default {
  components: {
    OrderCreateForm,
    OrderDetailTable,
    OrderCreateActionForm,
    OrderDetailModal,
    OrderPromoList
  },
  mixins: [orderCreateMixin, loadingMixin, orderDetailsFormMixin, productBundleMixins],
  data() {
    return {
      success: false,
      paymentMethods: [
        {
          isUsed: false,
          balance_used: 'restock',
          amount: null,
        },
        {
          isUsed: false,
          balance_used: 'multiplied_deposit',
          amount: null,
        },
      ],
      selectedBundles: [],
      choosenBonuses: {}
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      me: 'auth/getUser',
      getAll: 'main/getAll',
    }),
    orderDetailsForPromoList() {
      return this.orderDetails
        .filter(item => item.product.id)
        .map(item => ({
          productId: item.product.id,
          qty: item.qty,
          productCode: item.product.code 
        }))
    },
    productBundles() {
      return this.getAll['product-bundles']?.data ?? [];
    },
    balanceUsedTypes() {
      return {
        restock: {
          label: 'Saldo Restock',
          value:
            this.order.relationships.destinationOffice.attributes
              .restock_balance ?? 0,
        },
        multiplied_deposit: {
          label: 'Nilai Deposit Awal / Tambahan',
          value:
            this.order.relationships.destinationOffice.attributes
              .multiplied_deposit_balance ?? 0,
        },
      };
    },
  },
  async created() {
    this.startLoading('Menyiapkan Faktur');

    try {
      await Promise.all([
        this.setupMinOrder('min_order_spend_for_sc'),
        this.setupOfficeBanks({ officeId: this.me.office_id }),
        this.setupOriginOffice({ officeId: this.me.office_id }),
        this.$route.params.id
          ? this.setupDetailOrder()
          : this.setupBuyerType(10),
      ]);

      if (this.$route.params.id) {
        this.setOrderDetails();
        this.loadProductBundles(this.$route.params.id);
      }
    } finally {
      this.stopLoading();
    }

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  methods: {
    ...mapActions({
      fetchOffices: 'offices/fetchOffices',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      fetchAllRelated: 'main/fetchAllRelated',
    }),
    handleCreatedOrder(order) {
      this.order.id = order.id;
      this.order.attributes.is_valid_for_payment = false;

      this.$router.push(`/penjualan/faktur-sc/${order.id}`);
    },
    handleUpdatedOrderDetail() {
      this.loadProductBundles(this.$route.params.id);
    },
    handleClose: function () {
      this.$router.push('/penjualan/faktur-sc');
    },
    handleChangePaymentMethodAmount(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.paymentMethods[index].amount = value;

      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    async handleProcessOrder() {
      try {
        await this.storePaymentMethods(
          this.getOrder.data.relationships.payments.data[0].id
        );

        this.startLoading('Memproses Faktur');

        const order = await this.validateOrder({
          selected_bundles_id: this.selectedBundles,
          choosen_bonuses: this.choosenBonuses
        });

        if (order) {
          await this.fetchOrder({ id: this.order.id });

          this.success = true;
        }
      } catch (err) {
        //
      } finally {
        this.stopLoading();
      }
    },
    async setupDetailOrder() {
      await this.setupOrder(this.$route.params.id);
    },
    async setupPaymentMethods() {},
    async storePaymentMethods(paymentId) {
      this.startLoading('Menyimpan Pembayaran');

      try {
        await Promise.all(
          this.paymentMethods
            .filter((paymentMethod) => paymentMethod.isUsed)
            .map(async (paymentMethod) => {
              return await this.createPaymentMethod({
                attributes: {
                  balance_used: paymentMethod.balance_used,
                  payment_amount: paymentMethod.amount,
                },
                paymentId,
              });
            })
        );
      } catch (err) {
        this.stopLoading();

        throw err;
      }
    },
    onChangeSelectedBundles(value) {
      this.selectedBundles = [ ...value ]
    },
    onChangeChoosenBonuses(value) {
      this.choosenBonuses = { ...value }
    }
  },
};
</script>

<template>
  <section class="space-y-4">
    <div class="">
      <h3 class="text-lg font-bold leading-6 text-gray-900">Rangkuman</h3>
    </div>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      <div class="overflow-hidden rounded-lg bg-white p-4 shadow">
        <div class="relative">
          <div class="flex items-center">
            <div class="w-0 flex-1">
              <div>
                <div class="flex items-center justify-between md:block lg:flex">
                  <div class="truncate text-sm font-medium text-gray-900">
                    Total Laporan Tervalidasi
                  </div>
                  <div class="truncate text-sm font-bold text-gray-700"></div>
                </div>
                <div class="">
                  <div class="text-lg font-bold text-gray-900">
                    {{ getOrderBundles.data.length ?? 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute -bottom-4 -right-3 flex-shrink-0 text-gray-200"
            >
              <!-- Heroicon name: outline/scale -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-16 w-16"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LaporanHeader',
  components: {},
  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
    }),
  },
};
</script>

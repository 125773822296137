<template>
  <BaseModal
    :showing="visible"
    @close="onClose"
    :showClose="true"
    size="max-w-6xl"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Daftar Stockist
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Berdasarkan data stockist yang telah dibuat
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search @input="onSearch" placeholder="Cari kode atau nama" />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <data-table
            :total="getSimpleOffices.meta.page.total"
            :perPage="getSimpleOffices.meta.page.perPage"
            :currentPage="getSimpleOffices.meta.page.currentPage"
            @pagechanged="onPageChange"
            :meta="getSimpleOffices.meta"
            cursor
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Stockist
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Stockist
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Alamat
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    No. Telp
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getSimpleOffices.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="changeOffice(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ getOfficeIncluded(data).attributes.code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ getOfficeIncluded(data).attributes.name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ getOfficeIncluded(data).attributes.address }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ getOfficeIncluded(data).attributes.phone }}
                  </td>
                </tr>
              </tbody>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        @click="onClose"
        type="button"
        class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
      >
        Tutup
      </button>
    </div>
  </BaseModal>
</template>
<script>
import debounce from 'debounce';
import { mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import DataTable from '@/components/base/Datatable.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'OfficeModal',
  props: {
    visible: {
      required: true,
    },
  },
  components: {
    BaseModal,
    BaseSearch,
    DataTable,
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    changeOffice(payload) {
      this.$emit(
        'change',
        this.getSingleIncluded(
          this.getSimpleOffices,
          payload.relationships.office.data.id
        )
      );
    },
    onSearch: debounce(function (event) {
      this.$emit('search', event);
    }, 400),
    onPageChange(page) {
      this.$emit('page-change', page);
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSimpleOffices,
        simpleOffice.relationships.office.data.id
      );
    },
  },
};
</script>

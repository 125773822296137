<template>
  <base-card class="space-y-4">
    <div class="flex items-center justify-between">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Klasifikasi Harga Barang
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Klasifikasi Harga Barang Retur Per Kondisi
        </p>
      </div>
    </div>
    <div class="border-t border-gray-200 pt-4">
      <Datatable :paginated="false" :footer="false">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Kode
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Nama
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Jumlah
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              ></th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Total Harga
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              ></th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <template v-for="(detail, index) in returDetailPrices">
              <tr
                :key="index"
                class="cursor-pointer bg-white hover:bg-green-100"
                @click="onToggleRow(index)"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ detail.product_code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ detail.product_name }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.product_qty | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  colspan="2"
                >
                  {{ detail.total_price | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mx-auto h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                      v-if="visibleIndexRows.includes(index)"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                      v-else
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
              <tr
                v-if="detail.expired_count"
                v-show="visibleIndexRows.includes(index)"
                class="bg-gray-50"
                :key="`detail_kadaluarsa_${index}`"
              >
                <td></td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  Kadaluarsa
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.expired_count }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <product-price-select
                    :product-prices="detail.product_prices"
                    @change="
                      (price) => onChangeStatusPrice(index, 'expired', price)
                    "
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.expired_total_price | toCurrency }}
                </td>
                <td></td>
              </tr>
              <tr
                v-if="detail.damaged_count"
                v-show="visibleIndexRows.includes(index)"
                class="bg-gray-50"
                :key="`detail_rusak_${index}`"
              >
                <td></td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  Rusak
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.damaged_count }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <product-price-select
                    :product-prices="detail.product_prices"
                    @change="
                      (price) => onChangeStatusPrice(index, 'damaged', price)
                    "
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.damaged_total_price | toCurrency }}
                </td>
                <td></td>
              </tr>
              <tr
                v-if="detail.suitable_for_sale_count"
                v-show="visibleIndexRows.includes(index)"
                class="bg-gray-50"
                :key="`detail_layak_jual_${index}`"
              >
                <td></td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  Layak Jual
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.suitable_for_sale_count }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <product-price-select
                    :product-prices="detail.product_prices"
                    @change="
                      (price) =>
                        onChangeStatusPrice(index, 'suitable_for_sale', price)
                    "
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.suitable_for_sale_total_price | toCurrency }}
                </td>
                <td></td>
              </tr>
              <tr
                v-if="detail.unsuitable_for_sale_count"
                v-show="visibleIndexRows.includes(index)"
                class="bg-gray-50"
                :key="`detail_tidak_layak_jual_${index}`"
              >
                <td></td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  Tidak Layak Jual
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.unsuitable_for_sale_count }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <product-price-select
                    :product-prices="detail.product_prices"
                    @change="
                      (price) =>
                        onChangeStatusPrice(index, 'unsuitable_for_sale', price)
                    "
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ detail.unsuitable_for_sale_total_price | toCurrency }}
                </td>
                <td></td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                colspan="3"
              >
                Total Harga
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                colspan="2"
              >
                {{ totalPrice | toCurrency }}
              </td>
            </tr>
          </tfoot>
        </template>
      </Datatable>
    </div>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import ProductPriceSelect from '@/components/product/ProductPriceSelect.vue';
import { sum } from '@/utils/array';

export default {
  components: {
    Datatable,
    ProductPriceSelect,
  },
  emits: ['input'],
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      visibleIndexRows: [],
    };
  },
  computed: {
    ...mapGetters({
      getReturDetails: 'retur_details/getReturDetails',
    }),
    returDetailPrices: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    totalPrice() {
      return sum(this.returDetailPrices, (item) => item.total_price);
    },
  },
  methods: {
    ...mapActions({
      fetchProductPrices: 'product_prices/fetchProductPrices',
    }),
    async onToggleRow(index) {
      if (this.visibleIndexRows.includes(index)) {
        this.visibleIndexRows.splice(
          this.visibleIndexRows.findIndex((item) => item === index),
          1
        );
      } else {
        const isReturDetailPriceProductPricesLoaded =
          this.returDetailPrices[index].product_prices.length;

        if (!isReturDetailPriceProductPricesLoaded) {
          this.loadReturDetailPriceProductPrice(index);
        }

        this.visibleIndexRows.push(index);
      }
    },
    onChangeStatusPrice(index, status, productPrice) {
      const returDetailPrice = this.returDetailPrices[index];

      this.returDetailPrices[index][`${status}_product_price_id`] = productPrice
        ? productPrice.id
        : null;
      this.returDetailPrices[index][`${status}_price`] = productPrice
        ? productPrice.price
        : null;
      this.returDetailPrices[index][`${status}_total_price`] = productPrice
        ? productPrice.price * returDetailPrice[`${status}_count`]
        : 0;

      this.calculateReturDetailTotalPrice(index);
    },
    calculateReturDetailTotalPrice(index) {
      const returDetailPrice = this.returDetailPrices[index];

      this.returDetailPrices[index].total_price =
        returDetailPrice.expired_total_price +
        returDetailPrice.damaged_total_price +
        returDetailPrice.suitable_for_sale_total_price +
        returDetailPrice.unsuitable_for_sale_total_price;
    },
    async loadReturDetailPriceProductPrice(index) {
      const res = await this.fetchProductPrices({
        productId:
          this.getReturDetails.data[index].relationships.product.data.id,
        params: {
          'fields[product-prices]': 'price,buyer-type,area',
        },
      });

      if (res) {
        this.returDetailPrices[index].product_prices = res.data.data.map(
          (productPrice) => ({
            id: productPrice.id,
            price: productPrice.attributes.price,
            buyerType: productPrice.attributes['buyer-type'],
            area: productPrice.attributes.area,
          })
        );
      }
    },
  },
};
</script>

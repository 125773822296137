<script>
export default {
  name: 'AdvancedDropdown',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    select(option) {
      this.$emit('input', option);
      this.isOpen = false;
    },
    handleClickOutside() {
      this.isOpen = false;
    },
  },
  computed: {
    selected() {
      return this.options.find((option) => option.value === this.value);
    },
  },
};
</script>
<template>
  <div class="relative" v-click-outside="handleClickOutside">
    <base-button
      color="white"
      @click="toggle"
      id="dropdownDefault"
      data-dropdown-toggle="dropdown"
      type="button"
    >
      Filter
      <svg
        class="ml-2 h-4 w-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </base-button>

    <!-- Dropdown menu -->
    <div
      v-if="isOpen"
      id="dropdown"
      class="absolute right-0 z-10 mt-2 w-96 rounded-lg bg-white p-3 shadow dark:bg-gray-700"
    >
      <h6 class="mb-3 text-sm font-medium text-gray-900 dark:text-white">
        Daftar Filter
      </h6>
      <ul class="space-y-2 text-sm" aria-labelledby="dropdownDefault">
        <slot name="filter"></slot>
      </ul>
    </div>
  </div>
</template>

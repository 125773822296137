<template>
  <div class="py-2 sm:px-6 mx-2">
    <BaseCard class="space-y-4">
      <retur-header
        v-model="filter.search"
        @search="onSearch"
        :to="{
          name: 'create.retur.supplier',
        }"
      />
      <retur-table @page-change="onPageChange" @click-row="onDetail" />
    </BaseCard>

    <loading v-if="isLoadingRetur" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import ReturHeader from '@/components/retur/retur-header.vue';
import ReturTable from '@/components/retur/retur-table.vue';
import BaseCard from '@/components/base/BaseCard.vue';

export default {
  name: 'ReturSupplierIndex',
  components: {
    ReturTable,
    ReturHeader,
    BaseCard,
  },
  data() {
    return {
      filter: {
        search: null
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoadingRetur: 'returs/getLoading',
      getOfficeCategoryByName: 'office_categories/getOfficeCategoryByName'
    }),
  },
  methods: {
    ...mapActions({
      fetchReturs: 'returs/fetchReturs',
    }),
    onSearch: debounce(function () {
      this.loadReturs()
    }, 300),
    onPageChange(page) {
      this.loadReturs(page)
    },
    onDetail(data) {
      this.$router.push({
        name: 'edit.retur.supplier',
        params: {
          id: data.id,
        },
      });
    },
    loadReturs(page) {
      const supplierCategory = this.getOfficeCategoryByName('Supplier')

      this.fetchReturs({
        params: {
          'include': 'destination-office,origin-office',
          'filter[origin_office_category_id]': supplierCategory.id,
          'filter[search]': this.filter.search,
          'page[size]': 5,
          'page[number]': page,
          'fields[returs]': 'origin_code,updatedAt,destination-office,origin-office,total_price,is_valid_for_shipment',
          'fields[offices]': 'code',
          'sort': '-createdAt'
        }
      });
    }
  },
  created() {
    this.loadReturs()
  },
};
</script>

<template>
  <div class="flex items-center justify-end space-x-2">
    <base-select
      :expand="false"
      :shadow="false"
      :options="filterStatusOptions"
      v-model="filter.is_completed"
      @change="$emit('filter')"
    />
    <base-search
      class="h-10"
      placeholder="Cari Kode"
      v-model="filter.search"
      v-on:input="$emit('search')"
    />
  </div>
</template>

<script>
import BaseSearch from '@/components/base/Search.vue';

export default {
  components: {
    BaseSearch,
  },
  props: {
    value: Object,
  },
  emits: ['input', 'filter', 'search'],
  computed: {
    filter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    filterStatusOptions() {
      return [
        {
          value: null,
          label: 'Semua',
          key: 'Semua',
        },
        {
          value: false,
          label: 'Belum Divalidasi',
          key: 'Belum Divalidasi',
        },
        {
          value: true,
          label: 'Selesai',
          key: 'Selesai',
        },
      ];
    },
  },
};
</script>

<template>
  <div class="mx-auto my-4 px-4 sm:px-4 lg:px-8 space-y-5">
    <BaseCard title="Faktur" description="Tambah Faktur Penjualan">
      <template #action>
        <base-badge v-if="is_detail"> Draft </base-badge>
      </template>
      <div class="">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3" v-if="isEditForm">
            <base-input
              v-model="dataPenjualan.faktur.no_invoice"
              type="text"
              name="no_invoice"
              id="no_invoice"
              disabled
              label="Nomor Faktur"
              inset
              placeholder="Nomor Faktur"
            />
          </div>
          <div :class="[isEditForm ? 'sm:col-span-3' : 'sm:col-span-6']">
            <base-input
              v-model="dataPenjualan.faktur.tanggal"
              disabled
              type="date"
              name="tanggal"
              id="tanggal"
              label="Tanggal"
              inset
            />
          </div>

          <div class="sm:col-span-3">
            <div class="relative">
              <button
                type="button"
                :disabled="isEditForm"
                @click="openModalStockist"
                class="flex absolute inset-y-0 top-4 right-4 z-10 cursor-pointer items-center"
              >
                <Icon
                  icon="heroicons:magnifying-glass-circle-20-solid"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                />
              </button>
              <base-input
                v-model="dataPenjualan.faktur.kode_stockist"
                ref="focusNamaStockist"
                @keypress.enter="blurOfficeCode"
                @blur="blurOfficeCode()"
                type="text"
                name="name"
                id="name"
                :disabled="isEditForm"
                inset
                label="Kode Stockist"
                placeholder="Masukkan Kode Stockist"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <base-input
              v-model="dataPenjualan.faktur.nama_pemilik"
              type="text"
              label="Nama Stockist"
              name="nama_stockist"
              id="nama_stockist"
              inset
              disabled
            />
          </div>

          <div class="sm:col-span-3">
            <div class="relative">
              <button
                type="button"
                :disabled="isEditForm"
                @click="openModalGudang"
                class="flex absolute inset-y-0 top-4 right-4 z-10 cursor-pointer items-center"
              >
                <Icon
                  icon="heroicons:magnifying-glass-circle-20-solid"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                />
              </button>
              <base-input
                label="Kode Gudang"
                v-model="dataPenjualan.faktur.kode_gudang"
                @blur="handleChangeGudang"
                type="text"
                name="name"
                id="name"
                inset
                :disabled="isEditForm"
                placeholder="Masukkan Kode Stockist"
              />
            </div>
          </div>
          <div
            v-if="!checkSelectedBuyerType('Karyawan', 'Khusus', 'Free')"
            class="sm:col-span-3"
          >
            <base-input
              v-model="dataPenjualan.faktur.wilayah_harga"
              @change="changeArea"
              label="Wilayah Harga"
              type="text"
              name="wilayah_harga"
              id="wilayah_harga"
              inset
              placeholder="Masukkan Wilayah Harga"
              :disabled="
                checkSelectedStockistType('KYN') ||
                checkSelectedBuyerType('Karyawan', 'Khusus', 'Free') ||
                isEditForm
              "
            />
          </div>

          <div
            class="sm:col-span-3"
            v-if="checkSelectedStockistType('SCN', 'PRSH')"
          >
            <base-input :disabled="isEditForm" inset label="Tipe Harga">
              <base-select
                inset
                custom-height="h-7"
                size="sm"
                v-model="dataPenjualan.faktur.buyer_type"
                name="buyer_type"
                id="buyer_type"
                placeholder="Pilih Tipe Harga"
                :disabled="isEditForm"
                @change="handleChangeTipeHarga"
              >
                <option :value="null">Pilih Tipe Harga</option>
                <template v-for="(tipe, idx) in tipeHarga">
                  <option
                    v-if="useTipeHargaOption(tipe)"
                    :key="idx"
                    :value="tipe"
                  >
                    {{ tipe?.attributes?.name }} ({{ tipe?.attributes?.code }})
                  </option>
                </template>
              </base-select>
            </base-input>
          </div>

          <div
            class="sm:col-span-3"
            v-if="
              checkSelectedStockistType('SCN', 'PRSH') &&
              !checkSelectedBuyerType('Free')
            "
          >
            <base-input
              @input="changeDiscount"
              type="number"
              label="Discount(dalam persen)"
              name="discount"
              id="discount"
              max="100"
              min="0"
              inset
              placeholder="Masukkan Diskon"
              :disabled="isEditForm"
              v-model="dataPenjualan.faktur.discount"
            />
          </div>

          <div
            v-if="
              checkSelectedStockistType('SCN', 'PRSH') &&
              !checkSelectedBuyerType('Free')
            "
            class="sm:col-span-6"
          >
            <label
              for="limit_restock"
              class="my-3 block cursor-pointer text-sm font-medium text-gray-700"
            >
              <input
                id="limit_restock"
                v-model="dataPenjualan.faktur.is_minus_bv"
                type="checkbox"
                name="balance"
                value="restock_balance"
                :max="dataPenjualan.faktur.restock_balance"
                min="0"
                class="mr-2 h-4 w-4 rounded border-gray-300 focus:ring-indigo-500"
                :class="[
                  isEditForm ? 'text-gray-500 opacity-50' : 'text-indigo-600',
                ]"
                :disabled="isEditForm"
                @change="toggleMinusBv"
              />
              Harga - BV
            </label>
          </div>

          <div class="sm:col-span-6">
            <label for="name" class="text-xs font-bold text-gray-700">
              Metode Pengiriman
            </label>
            <div class="flex my-3 items-center space-x-8">
              <!-- https://prioritasweb.atlassian.net/browse/NASA-1032 [FE] Penjualan ke stockist selain Stockist, HD, HK maka hanya bisa diambil tidak bisa di kirim -->
              <div
                class="items-center text-sm text-gray-700"
                v-if="
                  isEditForm ? dataPenjualan.faktur.metode === 'pickup' : true
                "
              >
                <input
                  v-model="dataPenjualan.faktur.metode"
                  @change="changeMetode"
                  type="radio"
                  name="metode_pengiriman"
                  value="pickup"
                  class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                  :class="[
                    isEditForm ? 'text-gray-500 opacity-50' : 'text-indigo-600',
                  ]"
                  :disabled="isEditForm"
                />
                Diambil
              </div>
              <div
                class="items-center text-sm text-gray-700"
                v-if="
                  !checkSelectedBuyerType('Khusus', 'Free') &&
                  (isEditForm
                    ? dataPenjualan.faktur.metode === 'delivery'
                    : true)
                "
              >
                <input
                  v-model="dataPenjualan.faktur.metode"
                  @change="changeMetode"
                  type="radio"
                  name="metode_pengiriman"
                  value="delivery"
                  class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                  :class="[
                    isEditForm ? 'text-gray-500 opacity-50' : 'text-indigo-600',
                  ]"
                  :disabled="isEditForm"
                />
                Dikirim
              </div>
            </div>
            <div
              v-if="
                dataPenjualan.faktur.metode === 'delivery' &&
                dataPenjualan.faktur.nama_pemilik
              "
              class="my-6"
            >
              <h3 class="text-sm font-semibold">Alamat Pengiriman</h3>
              <div v-if="usingCustomAddress" class="divide-y-2">
                <p class="mt-2 mb-3 text-gray-600">
                  {{
                    order.relationships.destinationAddress.attributes.alamat
                  }},
                  {{
                    order.relationships.destinationAddress.attributes.village
                      .name
                  }},
                  {{
                    order.relationships.destinationAddress.attributes.district
                      .name
                  }},
                  {{
                    order.relationships.destinationAddress.attributes.city.name
                  }},
                  {{
                    order.relationships.destinationAddress.attributes.province
                      .name
                  }}
                </p>
                <div class="py-3">
                  <button
                    type="button"
                    @click="openModalAddress"
                    class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                  >
                    Ganti Alamat
                  </button>
                  <button
                    type="button"
                    @click="resetAddress"
                    class="bg-white-600 ml-2 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                  >
                    Gunakan Alamat Lama
                  </button>
                </div>
              </div>
              <div v-else class="divide-y-2">
                <p class="mt-2 mb-3 text-gray-600">
                  {{ dataPenjualan.faktur.address }}
                </p>
                <div v class="py-3">
                  <button
                    type="button"
                    @click="openModalAddress"
                    class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                  >
                    Pilih Alamat Lain
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="sm:col-span-6">
            <label class="text-xs font-bold text-gray-700">
              Metode Penjualan
            </label>
            <div class="flex my-3 items-center space-x-8">
              <div
                class="items-center text-sm text-gray-700"
              >
                <input
                  v-model="form.is_using_stock"
                  type="radio"
                  :value="true"
                  class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  :class="[
                    isEditForm ? 'text-gray-500 opacity-50' : 'text-indigo-600',
                  ]"
                  :disabled="isEditForm"
                />
                Dengan Barang
              </div>
              <div
                class="items-center text-sm text-gray-700"
              >
                <input
                  v-model="form.is_using_stock"
                  type="radio"
                  :value="false"
                  class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                  :class="[
                    isEditForm ? 'text-gray-500 opacity-50' : 'text-indigo-600',
                  ]"
                  :disabled="isEditForm"
                />
                Tanpa Barang
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>

    <template
      v-if="
        dataPenjualan.faktur.nama_pemilik !== null &&
        dataPenjualan.faktur.id_gudang &&
        dataPenjualan.faktur.wilayah_harga !== null
      "
    >
      <BaseCard title="Barang" description="Tambah informasi barang.">
        <template v-slot:action> </template>
        <Datatable :paginated="false" :footer="false">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  KODE BARANG
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  NAMA BARANG
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  STOCK
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  P/N
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  JUMLAH
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  HARGA
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  TOTAL HARGA
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  AKSI
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  TOTAL BERAT
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  PV
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  BV
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  STATUS ORDER
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody>
              <tr
                class="bg-white"
                v-for="(barang, index) in dataPenjualan.barang"
                :key="index"
              >
                <td
                  class="flex relative items-center whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  <input
                    v-model="barang.searchCode"
                    @change="handleChangeProduct(index)"
                    type="text"
                    name="kode_barang"
                    ref="kode_barang"
                    class="block w-32 border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Kode barang"
                  />
                  <button
                    type="button"
                    @click="openModalProduct(index)"
                    class="flex absolute inset-y-0 top-0 right-0 cursor-pointer items-center pl-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-500 hover:text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ barang.nama_barang }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ barang.stock }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{
                    barang.nama_barang
                      ? parseInt(barang.point)
                        ? 'Point'
                        : 'Non Point'
                      : null
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  :class="{ 'bg-gray-300': !barang.status }"
                >
                  <input
                    v-if="barang.is_point !== null"
                    v-model="barang.productQty"
                    ref="jumlah_barang"
                    @input="(e) => handleInputProductQty(index, e)"
                    :disabled="!barang.status"
                    @change="handleChangeProductQty(index)"
                    type="text"
                    name="jumlah_barang"
                    placeholder="Jumlah"
                    class="block w-full border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    :class="{ 'bg-gray-300': !barang.status }"
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  :class="{ 'bg-gray-300': !barang.status }"
                >
                  {{ barang.total_harga | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  :class="{ 'bg-gray-300': !barang.status }"
                >
                  <span v-if="dataPenjualan.barang.length > 1">
                    {{ barang.grand_total_harga | toCurrency }}
                  </span>
                </td>
                <td
                  v-if="barang.kode_barang"
                  class="flex justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  <!-- aksi -->
                  <svg
                    @click="handleRemoveProduct(index)"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  :class="{ 'bg-gray-300': !barang.status }"
                >
                  {{ barang.total_berat }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  :class="{ 'bg-gray-300': !barang.status }"
                >
                  {{ barang.point | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  :class="{ 'bg-gray-300': !barang.status }"
                >
                  {{ barang.data?.data[0].attributes.bonus_value | toCurrency }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{
                    barang.nama_barang
                      ? barang.status
                        ? 'OPEN'
                        : 'CLOSE'
                      : null
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot class="bg-gray-50" v-if="dataPenjualan.barang.length > 1">
              <tr>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                  colspan="2"
                >
                  <!-- <base-button
                    block
                    @click="() => (openPromoModal = !openPromoModal)"
                    color="white"
                  >
                    <span class="font-bold">
                      {{ getAll['product-bundles']?.page?.total ?? 0 }}
                    </span>
                    Promo Tersedia
                  </base-button> -->
                </td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                >
                  Total
                </td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
                >
                  {{ getGrandTotalPrice() | toCurrency }}
                </td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
              </tr>
              <tr>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                >
                  Total Berat
                </td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
                >
                  {{ getGrandTotalWeight() | toFixed }} Kg
                </td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
              </tr>
              <tr>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-sm font-medium text-gray-500"
                >
                  Total PV
                </td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
                >
                  {{ dataPenjualan.faktur.is_minus_bv ? 0 : getGrandTotalPV() }}
                </td>
                <td
                  class="whitespace-nowrap px-6 pt-4 pb-0 text-right text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
              </tr>
              <tr>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500"
                >
                  Total BV
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-500"
                >
                  {{
                    dataPenjualan.faktur.is_minus_bv
                      ? 0
                      : getGrandTotalBV() | toCurrency
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-500"
                  colspan="5"
                ></td>
              </tr>
            </tfoot>
          </template>
        </Datatable>
        <div class="grid grid-cols-1 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <p
              v-if="
                !isOrderReachMinSpend() &&
                !checkSelectedStockistType('PRSH', 'SCN', 'KYN')
              "
              class="pt-4 text-xs font-light italic text-red-600"
            >
              Minimal belanja stockist Rp. {{ orderMinSpend | toCurrency }}
            </p>
            <template
              v-if="
                dataPenjualan.faktur.metode === 'pickup' &&
                form.is_using_stock &&
                canHaveCashback(dataPenjualan.faktur.kode_stockist)
              "
            >
              <p class="pt-4 text-xs font-light italic text-red-600">
                Cashback kelipatan belanja Rp.
                {{ cashbackMinOrder | toCurrency }}
              </p>
              <p
                v-if="cashbackRemainder"
                class="pt-4 text-xs font-light italic text-red-600"
              >
                Kurang Rp. {{ cashbackRemainder | toCurrency }} lagi supaya
                dapat cashback
              </p>
            </template>
          </div>
        </div>
      </BaseCard>

      <OrderPromoList v-if="form.is_using_stock && productBundles.length" :order-details="orderDetailsForPromoList" :product-bundles="productBundles" @change-selected-bundles="onChangeSelectedBundles" @change-choosen-bonuses="onChangeChoosenBonuses" />
      
      <OrderSummary
        :totalbarang="totalBarang"
        :dpp="dpp"
        :ppn="ppn"
        :totalharga="getGrandTotalPrice()"
        :cashback="cashback"
        :total-bayar="totalPaymentAmount"
        :with-cashback="
          dataPenjualan.faktur.metode === 'pickup' &&
          form.is_using_stock &&
          canHaveCashback(dataPenjualan.faktur.kode_stockist)
        "
        :with-remainder="form.is_using_stock"
      />
    </template>
    <!-- Start Metode Pembayaran -->
    <BaseCard
      title="Metode Pembayaran"
      description="Buat Pembayaran Pada Faktur Penjualan Ini."
      v-if="form.is_using_stock && dataPenjualan.barang.length > 1 && getGrandTotalPrice()"
    >
      <!-- start saldo pembayaran -->
      <div
        v-if="getOrderBalanceUsed() > 0"
        class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
      >
        <div class="relative w-full">
          <div class="field-inset-default bg-gray-100">
            <label
              for="metode_saldo_pembayaran"
              class="block text-xs font-bold text-gray-700"
            >
              Metode Pembayaran
            </label>
            <div
              id="metode_saldo_pembayaran"
              class="input-inset-select disabled:bg-gray-100"
            >
              Saldo Pembayaran ({{ officeOrderBalance | toCurrency }})
            </div>
          </div>
        </div>
        <div class="relative w-full">
          <div class="field-inset-default bg-gray-100">
            <label
              for="saldo_pembayaran"
              class="block text-xs font-bold text-gray-700"
            >
              Nominal Pembayaran
            </label>
            <input
              type="text"
              name="saldo_pembayaran"
              id="saldo_pembayaran"
              class="input-inset-select disabled:bg-gray-100"
              placeholder="Masukkan Nominal Pembayaran"
              :value="getOrderBalanceUsed() | toCurrency"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- end saldo pembayaran -->
      <PaymentMethodInlineForm
        :methods="paymentMethodTypes"
        :payment-id="getIncludedByType(getOrder, 'payments')[0].id"
        auto-store
        v-model="paymentMethods"
      />
    </BaseCard>
    <!-- End Metode Pembayaran -->

    <OrderNotesForm v-if="form.is_using_stock" v-model="dataPenjualan" :options="cetakPdf" />

    <div class="pt-5">
      <div class="flex justify-end">
        <button
          v-if="
            !isOrderReachMinSpend() &&
            !checkSelectedStockistType('PRSH', 'SCN', 'KYN')
          "
          disabled
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm"
        >
          Siap Validasi
        </button>
        <button
          v-else
          @click="handleConfirm"
          id="simpan"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Siap Validasi
        </button>
        <router-link
          to="/penjualan/transaksi-penjualan"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
        >
          Selesai
        </router-link>
      </div>
    </div>

    <!-- Modal Confirmation -->
    <ConfirmModal
      :visible="addStatus"
      :isEdit="isEditForm"
      @close="() => (addStatus = !addStatus)"
      @saving="handleSave"
    />

    <OfficeModal
      :visible="openListStockist"
      @close="() => (openListStockist = false)"
      @search="debounceSearchStockist"
      @page-change="onPagechangeStockists"
      @change="changeStockist"
    />

    <WarehouseModal
      :visible="openListGudang"
      @close="() => (openListGudang = false)"
      @search="searchWarehouse"
      @page-change="onPagechangeGudang"
      @change="changeGudang"
    />

    <AddressModal
      v-model="order"
      :visible="openAddAddress"
      @close="openAddAddress = false"
      @change="handleChangeAddress"
      :destinationOffice="{
        id: StorageService.getUser().office_id,
      }"
    />

    <stock-table-modal
      :visible="openListProductByStockist"
      :params="{
        warehouseId: dataPenjualan.faktur.id_gudang,
        'filter[seller_office_code]': sellerOfficeCode,
        'filter[buyer_office_code]': selectedStockistCodeWithoutArea,
        'filter[area_code]': selectedArea,
        'filter[buyer_type_code]': dataPenjualan.faktur.kode_buyer_type,
      }"
      @close="() => (openListProductByStockist = false)"
      @click-product="changeProduct"
    />

    <!-- Modal Detail -->
    <order-detail-modal
      :visible="success"
      :order="getOrder.data"
      :editable="true"
      :deleteable="false"
      with-bonus
      @close="handleClose"
      @edit="handleEditOrder"
      @refresh="handleClose"
    />

    <loading
      v-if="
        isLoadingStockist ||
        isLoadingStocks ||
        isLoadingWarehouses ||
        isLoadingOrder ||
        isLoadingOffices ||
        isLoadingProductForm ||
        isLoadingSimpleOffice
      "
      :text="loadingText"
    ></loading>
    <BaseModal
      :showing="openPromoModal"
      @close="() => (openPromoModal = false)"
      :showClose="true"
    >
      <PromoList />
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import { fetchDetailOrderParams } from '@/services/common.service';
import OrderSummary from '@/components/order/OrderSummary.vue';
import StockTableModal from '@/components/stock/StockTableModal.vue';
import WarehouseModal from '@/components/modal/warehouse-modal.vue';
import OfficeModal from '@/components/penjualan/create/office-modal.vue';
import ConfirmModal from '@/components/penjualan/create/confirm-modal.vue';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm';
import { cashbackMixin } from '@/mixins/office-category/cashback.mixin';
import OrderNotesForm from '@/components/order/OrderNotesForm.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import BaseModal from '@/components/base/BaseModal';
import PromoList from '@/components/promo/promo-list.vue';
import AddressModal from '@/components/order/create/AddressModal.vue';
import { productBundleMixins } from '@/mixins/product-bundle/product-bundle.mixin';
import OrderPromoList from '@/components/promo/OrderPromoList.vue';

export default {
  name: 'TambahPenjualan',
  components: {
    Datatable,
    BaseModal,
    OrderDetailModal,
    AddressModal,
    OrderSummary,
    StockTableModal,
    WarehouseModal,
    OfficeModal,
    ConfirmModal,
    PaymentMethodInlineForm,
    OrderNotesForm,
    BaseCard,
    PromoList,
    OrderPromoList,
  },
  mixins: [cashbackMixin, productBundleMixins],
  data() {
    return {
      StorageService,
      usingCustomAddress: false,
      loadingText: 'loading',
      isLoadingProductForm: false,
      is_detail: false,
      addStatus: false,
      success: false,
      failed: false,
      openDetail: false,
      openPromoModal: false,
      openListStockist: false,
      openListGudang: false,
      openListProductByStockist: false,
      openAddAddress: false,
      selectedOffice: null,
      cetakPdf: {
        cetak: false,
        download: false,
      },
      search: {
        stockist: '',
        gudang: '',
        product: '',
      },
      tipeHarga: [
        'Stockist',
        'Konsumen',
        'Distributor',
        'PRSH',
        'Karyawan',
        'Khusus',
        'Free',
        'Mitra Usaha',
      ],
      tipeHargaKaryawan: ['PRSH', 'Khusus', 'Free'],
      productIndex: null,
      error: null,
      sellerOfficeCode: null,
      paymentMethods: [],
      dataPenjualan: {
        faktur: {
          id: null,
          canceled_from: null,
          no_invoice: null,
          tanggal: dayjs().format('YYYY-MM-DD'),
          id_buyer_type: null,
          kode_buyer_type: null,
          id_stockist: null,
          id_gudang_stockist: null,
          kode_stockist: null,
          area_stockist: null,
          nama_pemilik: null,
          id_gudang: null,
          kode_gudang: null,
          area_gudang: '0',
          id_wilayah_harga: null,
          wilayah_harga: null,
          tmp_wilayah_harga: 0,
          metode: 'pickup',
          saldo_awal: null,
          saldo_order_awal: null,
          address: null,
          city: null,
          phone: null,
          notes: null,
          payment_method: '',
          buyer_type: null,
          discount: 0,
          is_minus_bv: false,
          destination_address: {
            province: {
              id: '',
              name: '',
            },
            city: {
              id: '',
              name: '',
              area: '',
            },
            district: {
              id: '',
              name: '',
            },
            village: {
              id: '',
              name: '',
            },
            alamat: null,
            status: false,
          },
        },
        barang: [
          {
            id_barang: null,
            kode_barang: null,
            nama_barang: null,
            stock: null,
            status: true,
            is_point: null,
            jumlah: null,
            harga: null,
            discount: 0,
            berat: null,
            total_harga: null,
            total_berat: null,
            bonus: 0,
            point: 0,
            grand_total_harga: 0,
            searchCode: null,
            productQty: null,
            productId: null,
            orderDetailId: null,
          },
        ],
      },
      projects: [
        {
          name: 'Graph API',
          initials: 'GA',
          href: '#',
          members: 16,
          bgColor: 'bg-pink-600',
        },
        {
          name: 'Component Design',
          initials: 'CD',
          href: '#',
          members: 12,
          bgColor: 'bg-purple-600',
        },
        // { name: 'Templates', initials: 'T', href: '#', members: 16, bgColor: 'bg-yellow-500' },
        // { name: 'React Components', initials: 'RC', href: '#', members: 8, bgColor: 'bg-green-500' },
      ],
      order: {
        id: null,
        attributes: {
          createdAt: null,
          is_ppn_included: true,
          order_shipment: 'pickup',
          destination_address: {
            village: null,
            city: null,
            address: null,
            district: null,
            province: null,
          },
          notes: '',
        },
        relationships: {
          orderDetails: {
            data: [],
          },
          paymentMethods: {
            data: [],
          },
          originWarehouse: {
            attributes: {
              area_code: null,
            },
          },
          destinationAddress: {
            attributes: {
              province: {
                id: null,
                name: null,
              },
              city: {
                id: null,
                name: null,
                area: null,
              },
              district: {
                id: null,
                name: null,
              },
              village: {
                id: null,
                name: null,
              },
              alamat: '',
            },
          },
        },
      },
      selectedBundles: [],
      choosenBonuses: {},
      form: {
        is_using_stock: true
      }
    };
  },
  async created() {
    this.setOfficeBank();
    this.setSetupsForMinOrder();

    this.fetchPaymentMethodTypesByOffice({
      officeId: this.me.office_id,
      params: { 'filter[is_reserved]': false },
    });

    if (this.$route.params.id) {
      this.loadOrder(this.$route.params.id).then((response) => {
        this.setOrder(response.data);
        this.loadProductBundles(this.$route.params.id);
      });
    } else {
      this.setWarehouse();
      this.setOffice();
      this.setBuyerTypes().then((res) => {
        res.data.data.forEach((buyerType) => {
          if (buyerType.attributes.name == 'Stockist') {
            this.dataPenjualan.faktur.buyer_type = buyerType;
            this.dataPenjualan.faktur.id_buyer_type = buyerType.id;
            this.dataPenjualan.faktur.kode_buyer_type =
              buyerType.attributes.code;
          }
        });
      });
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoadingStockist: 'stockists/getLoading',
      isLoadingStocks: 'stocks/getLoading',
      isLoadingWarehouses: 'warehouses/getLoading',
      isLoadingOrder: 'orders/getLoading',
      isLoadingOffices: 'offices/getLoading',
      getStockists: 'stockists/getStockists',
      getOffice: 'offices/getOffice',
      getWarehouseByOffice: 'warehouses/getWarehouseByOffice',
      getWarehouses: 'warehouses/getWarehouses',
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getStocks: 'stocks/getStocks',
      getOrder: 'orders/getOrder',
      getBanksByOffice: 'banks/getBanksByOffice',
      getPaymentMethods: 'payments/getPaymentMethods',
      getSetups: 'setups/getSetups',
      getPaymentMethodTypes: 'payment_method_types/getPaymentMethodTypes',
      me: 'auth/getUser',
      isLoadingSimpleOffice: 'simple_offices/getLoading',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getAll: 'main/getAll',
    }),
    orderDetailsForPromoList() {
      return this.dataPenjualan.barang
        .filter(item => item.productId)
        .map(item => ({
          productId: item.productId,
          qty: item.productQty,
          productCode: item.kode_barang 
        }))
    },
    productBundles() {
      return this.getAll['product-bundles']?.data ?? [];
    },
    cashbackRemainder() {
      return Math.max(
        this.getCashbackMinOrder(
          'pusat',
          this.dataPenjualan.faktur.wilayah_harga
        ) - this.getGrandTotalPrice(),
        0
      );
    },
    cashbackMinOrder() {
      return this.getCashbackMinOrder(
        'pusat',
        this.dataPenjualan.faktur.wilayah_harga
      );
    },
    cashback() {
      if (this.dataPenjualan.faktur.metode !== 'pickup' || !this.form.is_using_stock) {
        return 0;
      }

      const result = this.getCashbackAmount(
        'pusat',
        this.dataPenjualan.faktur.wilayah_harga,
        this.getGrandTotalPrice()
      );
      if (isNaN(result)) {
        return 0;
      }
      return result;
    },
    officeOrderBalance() {
      return (
        this.dataPenjualan.faktur.stockist.attributes.order_balance_available +
        (this.getOrder.data ? this.getOrder.data.attributes.final_price : 0)
      );
    },
    paymentMethodTypes() {
      return this.getPaymentMethodTypes.data.map((type) => ({
        id: type.id,
        name: type.attributes.name,
        value: type.id,
      }));
    },
    totalPaymentAmount() {
      return (
        this.paymentMethods.reduce((curr, method) => curr + method.value, 0) +
        Math.max(this.officeOrderBalance, 0)
      );
    },
    paymentAmountRemainder() {
      const totalPaymentAmount = this.paymentMethods.reduce(
        (curr, method) => curr + method.value,
        0
      );

      return (
        this.getGrandTotalPrice() -
        (totalPaymentAmount + this.officeOrderBalance)
      );
    },
    selectedStockistCodeWithoutArea() {
      return this.dataPenjualan.faktur.kode_stockist?.split(' ')[0];
    },
    selectedArea() {
      // kode area pusat yg login
      const userWarehouseAreaCode = this.dataPenjualan.faktur.area_gudang;
      // kode area alamat pengiriman atau kode area stockist
      const selectedStockistAreaCode = this.dataPenjualan.faktur
        .destination_address.status
        ? this.dataPenjualan.faktur.destination_address.city.area
        : this.dataPenjualan.faktur.area_stockist;

      if (
        selectedStockistAreaCode === undefined ||
        selectedStockistAreaCode === null
      ) {
        return null;
      }

      // area wilayah harga jika metode transaksi diambil gunakan area kp jika dikirim dibandingkan yang tertinggi
      if (this.dataPenjualan.faktur.metode === 'diambil') {
        return userWarehouseAreaCode;
      }

      return Math.max(userWarehouseAreaCode, selectedStockistAreaCode);
    },
    orderMinSpend() {
      return parseInt(
        this.getSetups.data.length
          ? this.getSetups.data[0].attributes.setup_value
          : 300000
      );
    },
    grandtotal: function () {
      return this.dataPenjualan.barang.reduce(
        (total, product) => total + (product.grand_total_harga || 0),
        0
      );
    },
    totalBarang: function () {
      return this.dataPenjualan.barang.reduce(
        (total, product) => total + (parseInt(product.productQty) || 0),
        0
      );
    },
    dpp: function () {
      return (100 / 111) * this.grandtotal;
    },
    ppn: function () {
      return (11 / 100) * this.dpp;
    },
    isEditForm() {
      return !!this.dataPenjualan.faktur.id;
    },
    areaCode() {
      const areaCode = this.dataPenjualan.faktur.wilayah_harga;

      if (areaCode == 5 || areaCode == 6 || areaCode == 9) {
        return 0;
      }

      return areaCode;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchAll: 'main/fetchAll',
      fetchAllRelated: 'main/fetchAllRelated',
      fetchOne: 'main/fetchOne',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      fetchWarehouses: 'warehouses/fetchWarehouses',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchAreas: 'areas/fetchAreas',
      createOrder: 'orders/createOrder',
      createOrderWithParams: 'orders/createOrderWithParams',
      fetchOrder: 'orders/fetchOrder',
      onBeforeValidation: 'orders/onBeforeValidation',
      updateOrder: 'orders/updateOrder',
      createAlert: 'alert/createAlert',
      createPayment: 'payments/createPayment',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      deletePaymentMethod: 'payment_methods/deletePaymentMethod',
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchSetups: 'setups/fetchSetups',
      createOrderDetail: 'order_details/createOrderDetail',
      patchOrderDetail: 'order_details/updateOrderDetail',
      deleteOrderDetail: 'order_details/deleteOrderDetail',
      fetchPaymentMethodTypesByOffice:
        'payment_method_types/fetchPaymentMethodTypesByOffice',
      validate: 'orders/validate',
      fetchOfficesByCategory: 'offices/fetchOfficesByCategory',
    }),
    loadStockist(params = {}) {
      return this.fetchSimpleOffices({
        ...params,
        'filter[office_category_id]': '4',
        'filter[search]': this.search.stockist,
        'page[limit]': 5,
        'fields[simple-offices]': 'office',
        'fields[offices]':
          'phone,area,address,email,bank_account_number,code,name,order_balance_available,balance',
        include: 'office',
      });
    },
    isOrderReachMinSpend() {
      return this.getGrandTotalPrice() >= this.orderMinSpend;
    },
    getOrderBalanceUsed: function () {
      return Math.min(this.officeOrderBalance, this.getGrandTotalPrice());
    },
    setLoadingProduct(value, text = null) {
      this.isLoadingProductForm = value;

      if (value) {
        this.setLoading(text);
      }
    },
    loadOrder: async function (id) {
      this.dataPenjualan.faktur.id = id;

      this.setLoading('Loading Order');

      const response = await this.fetchOrder({
        id: this.dataPenjualan.faktur.id,
        fields: {
          orders:
            'canceled_from,createdAt,updatedAt,buyer-type,destination-office,destination_warehouse_id,origin-warehouse,origin-office,final_price,area,order-details,payment_details,discount_price,is_minus_bv,order_shipment,is_using_stock,origin_code,destination_code,is_valid_for_payment,notes,created_by,payments',
          offices: 'code,area,name,address,order_balance_available,balance',
          warehouses: 'code,area_code',
          areas: 'code',
          'order-details':
            'point_value,product_weight,bonus_value,total_price,product_price,product_id,is_point,product_qty,product_code,product_name,current_stock,total_weight',
        },
        include:
          'destination-office,origin-office,origin-warehouse,area,order-details,buyer-type,payments',
      });

      if (response && !response.data.data.attributes.is_valid_for_payment) {
        this.is_detail = true;
      }

      return response;
    },
    getOrderDetailsProduct(code) {
      return this.getOrder.included
        .filter((include) => include.type === 'order-details')
        .find((product) => product.attributes.product_code === code);
    },
    getStockOrderableStock(stock) {
      // jika tidak edit orderable stok langsung dari atribut
      if (!this.isEditForm) {
        return stock.attributes.orderable_stock;
      }

      const stockProductInOrderDetails = this.getOrderDetailsProduct(
        stock.attributes.product_code
      );

      // jika tidak ada stock produk di order details orderable stok langsung dari atribut
      if (!stockProductInOrderDetails) {
        return stock.attributes.orderable_stock;
      }

      // orderable stok + jumlah produk dibeli
      return (
        stock.attributes.orderable_stock +
        stockProductInOrderDetails.attributes.product_qty
      );
    },
    getGrandTotalPrice() {
      return this.dataPenjualan.barang.reduce(function (total, attributes) {
        return total + (attributes.grand_total_harga || 0);
      }, 0);
    },
    checkSelectedStockistType(...types) {
      return this.isStringInclude(
        this.dataPenjualan.faktur.kode_stockist ?? '',
        ...types
      );
    },
    checkSelectedBuyerType(...types) {
      return types.some(
        (type) => this.dataPenjualan.faktur.buyer_type?.attributes.name === type
      );
    },
    useTipeHargaOption(buyer_type) {
      const availableOptions = {
        PRSH: [
          'Stockist',
          'Free',
          'Konsumen',
          'Mitra Usaha',
          'PRSH',
          'Khusus',
          'Karyawan',
        ],
        SCN: [
          'Stockist',
          'Konsumen',
          'Mitra Usaha',
          'PRSH',
          'Khusus',
          'Karyawan',
        ],
      };
      const availableOptionsPerStockist =
        availableOptions[
          this.checkSelectedStockistType('PRSH') ? 'PRSH' : 'SCN'
        ];

      return this.isStringInclude(
        availableOptionsPerStockist,
        buyer_type.attributes?.name
      );
    },
    isStringInclude(source, ...str) {
      return str.some((char) => source.indexOf(char) !== -1);
    },
    countGrandTotal(index) {
      const harga = this.dataPenjualan.barang[index].harga;
      const productQty = this.dataPenjualan.barang[index].productQty;

      // const minusBvAmount = this.dataPenjualan.faktur.is_minus_bv
      //   ? this.dataPenjualan.barang[index].is_point
      //     ? this.dataPenjualan.barang[index].bonus
      //     : 0
      //   : 0;
      const discount = isNaN(this.dataPenjualan.faktur.discount)
        ? 0
        : parseFloat(this.dataPenjualan.faktur.discount);
      const discountAmount = harga * (discount / 100);

      this.dataPenjualan.barang[index].total_harga = Math.max(
        harga - discountAmount,
        0
      );
      this.dataPenjualan.barang[index].grand_total_harga = Math.max(
        harga * productQty - productQty * discountAmount,
        0
      );
    },
    changeDiscount(e) {
      this.dataPenjualan.faktur.discount = parseFloat(e.target.value);

      this.dataPenjualan.barang.forEach((barang, index) => {
        this.countGrandTotal(index);
      });
    },
    toggleMinusBv() {
      this.resetBarang();
    },
    openModalStockist() {
      this.setLoading('Loading Stockist');

      this.search.stockist = '';
      this.openListStockist = !this.openListStockist;

      this.loadStockist();
    },
    openModalGudang() {
      this.setLoading('Loading Gudang');

      this.openListGudang = !this.openListGudang;
      this.fetchWarehouses({
        pageNumber: 1,
        pageSize: 5,
        warehouseType: 'pusat,kantor_pelayanan',
      });
    },
    openModalProduct(index) {
      this.setLoading('Loading Barang');

      this.productIndex = index;
      this.openListProductByStockist = !this.openListProductByStockist;
      let wilayah_harga = this.dataPenjualan.faktur.wilayah_harga;
      if (wilayah_harga == 5 || wilayah_harga == 6 || wilayah_harga == 9) {
        wilayah_harga = 0;
      }
    },
    openModalAddress() {
      this.openAddAddress = !this.openAddAddress;
      this.fetchProvinces({
        pageNumber: 1,
      });
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataPenjualan.faktur.destination_address.province.id,
        pageNumber: 1,
      });
      this.dataPenjualan.faktur.destination_address.city = {
        id: '',
        name: '',
        area: '',
      };
      this.dataPenjualan.faktur.destination_address.district =
        this.dataPenjualan.faktur.destination_address.village = {
          id: '',
          name: '',
        };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataPenjualan.faktur.destination_address.city.id,
        pageNumber: 1,
      });
      this.dataPenjualan.faktur.destination_address.district =
        this.dataPenjualan.faktur.destination_address.village = {
          id: '',
          name: '',
        };
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataPenjualan.faktur.destination_address.district.id,
        pageNumber: 1,
      });
      this.dataPenjualan.faktur.destination_address.village = {
        id: '',
        name: '',
      };
    },
    saveAddress() {
      this.dataPenjualan.faktur.destination_address.status = true;
      this.resetBarang();
      this.updateArea();
      this.openAddAddress = !this.openAddAddress;
    },
    resetAddress() {
      this.usingCustomAddress = false;

      this.dataPenjualan.faktur.destination_address.province.id = '';
      this.dataPenjualan.faktur.destination_address.province.name = '';
      this.dataPenjualan.faktur.destination_address.city.id = '';
      this.dataPenjualan.faktur.destination_address.city.name = '';
      this.dataPenjualan.faktur.destination_address.city.area = '';
      this.dataPenjualan.faktur.destination_address.district.id = '';
      this.dataPenjualan.faktur.destination_address.district.name = '';
      this.dataPenjualan.faktur.destination_address.village.id = '';
      this.dataPenjualan.faktur.destination_address.village.name = '';
      this.dataPenjualan.faktur.destination_address.alamat = '';

      this.resetBarang();
      this.updateArea();
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.resetOrder();
    },
    changeStockist(data) {
      if (data.attributes.code.indexOf('KYN') === 0) {
        this.dataPenjualan.faktur.buyer_type = this.tipeHarga.find(
          (it) => it.attributes.name == 'PRSH'
        );
      }
      this.dataPenjualan.faktur.kode_stockist = `${data.attributes.code} (${data.attributes.area})`;
      this.blurOfficeCode();
      this.search.stockist = '';
      this.openListStockist = !this.openListStockist;
      this.resetBarang();
    },
    changeGudang(data) {
      this.dataPenjualan.faktur.kode_gudang = data.attributes.code;
      this.handleChangeGudang();
      this.openListGudang = !this.openListGudang;
    },
    onPagechangeStockists(page) {
      this.setLoading('Loading Stockist');
      this.loadStockist(page);
    },
    onPagechangeGudang(page) {
      this.setLoading('Loading Gudang');

      this.fetchWarehouses({
        pageNumber: page,
        pageSize: 5,
        search: this.search.gudang,
        warehouseType: 'pusat,kantor_pelayanan',
      });
    },
    debounceSearchStockist: debounce(function (value) {
      this.setLoading('Loading Stockist');

      this.search.stockist = value;
      this.loadStockist();
    }, 300),
    searchWarehouse: function (value) {
      this.setLoading('Loading Gudang');

      this.fetchWarehouses({
        pageNumber: 1,
        pageSize: 5,
        search: value,
        warehouseType: 'pusat,kantor_pelayanan',
      });
    },
    changeMetode() {
      this.updateArea();
      this.resetBarang();
    },
    changeArea() {
      let wilayah = 0;
      if (
        ['SCN', 'PRSH'].find(
          (it) =>
            this.dataPenjualan.faktur.kode_stockist.includes(it) &&
            this.dataPenjualan.faktur.wilayah_harga <= 4 &&
            this.dataPenjualan.faktur.wilayah_harga >= 0
        )
      ) {
        wilayah = this.dataPenjualan.faktur.wilayah_harga;
      } else if (this.dataPenjualan.faktur.metode == 'pickup') {
        this.dataPenjualan.faktur.wilayah_harga =
          this.dataPenjualan.faktur.area_gudang;
        wilayah = this.dataPenjualan.faktur.area_gudang;
      } else if (
        this.tipeHargaKaryawan.includes(
          this.dataPenjualan.faktur.buyer_type?.attributes.name
        )
      ) {
        this.dataPenjualan.faktur.wilayah_harga =
          this.dataPenjualan.faktur.buyer_type?.attributes.code;
        wilayah = 0;
      } else if (
        this.dataPenjualan.faktur.wilayah_harga > 4 ||
        this.dataPenjualan.faktur.wilayah_harga < 0
      ) {
        this.dataPenjualan.faktur.wilayah_harga = 0;
      } else {
        wilayah = this.dataPenjualan.faktur.wilayah_harga;
      }

      this.fetchAreas({
        keyword: wilayah,
      }).then((response) => {
        this.dataPenjualan.faktur.id_wilayah_harga = response.data.data[0].id;
      });

      this.resetBarang();
    },
    changeProduct(data) {
      if (this.getStockOrderableStock(data) > 0) {
        this.dataPenjualan.barang[this.productIndex].searchCode =
          data.attributes.product_code;
        this.handleChangeProduct(this.productIndex);
        this.openListProductByStockist = !this.openListProductByStockist;
        this.search.product = '';
      }
    },
    updateArea() {
      // kalo dikirim
      if (this.dataPenjualan.faktur.metode !== 'pickup') {
        if (this.dataPenjualan.faktur.destination_address.status) {
          // kalo kyn pindah alamat, label wilayah_harga tetep 5
          if (this.checkSelectedStockistType('KYN')) {
            this.dataPenjualan.faktur.wilayah_harga = 5;
          } else {
            this.dataPenjualan.faktur.wilayah_harga = Math.max(
              this.dataPenjualan.faktur.destination_address.city.area,
              this.dataPenjualan.faktur.area_gudang
            );
          }
        } else {
          const areaStockist = this.checkSelectedStockistType('PRSH')
            ? 9
            : this.checkSelectedStockistType('KYN')
            ? 5
            : this.dataPenjualan.faktur.area_stockist;
          this.dataPenjualan.faktur.wilayah_harga = Math.max(
            areaStockist,
            this.dataPenjualan.faktur.area_gudang
          );
        }
        // kalo diambil
      } else {
        const areaStockist = this.checkSelectedStockistType('KYN')
          ? 5
          : this.dataPenjualan.faktur.area_gudang;
        this.dataPenjualan.faktur.wilayah_harga = areaStockist;
      }
      this.fetchAreas({
        keyword:
          this.dataPenjualan.faktur.wilayah_harga > 4
            ? 0
            : this.dataPenjualan.faktur.wilayah_harga,
      }).then((response) => {
        this.dataPenjualan.faktur.id_wilayah_harga = response.data.data[0].id;
      });
    },
    async setOrder(options = {}) {
      const withOrderDetails = options.withOrderDetails ?? true;
      const withPayments = options.withPayments ?? true;

      const order = this.getOrder

      this.form.is_using_stock = order.data.attributes.is_using_stock

      this.sellerOfficeCode = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-office'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.stockist = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      );
      this.dataPenjualan.faktur.wilayah_harga =
        this.getOrder.data.attributes.area_code;
      this.dataPenjualan.faktur.buyer_type = this.getOrder.included.find(
        (it) => it.type == 'buyer-types'
      );
      this.tipeHarga.push(this.dataPenjualan.faktur.buyer_type);
      this.dataPenjualan.faktur.no_invoice = `${this.getOrder.data.attributes.origin_code}/${this.getOrder.data.attributes.destination_code}`;
      this.dataPenjualan.faktur.canceled_from =
        this.getOrder.data.attributes.canceled_from;
      this.dataPenjualan.faktur.tanggal = dayjs(
        this.getOrder.data.attributes.updatedAt
      ).format('YYYY-MM-DD');
      this.dataPenjualan.faktur.id_buyer_type = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['buyer-type'].data.id
      ).id;
      this.dataPenjualan.faktur.kode_buyer_type = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['buyer-type'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.id_stockist = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      ).id;
      this.dataPenjualan.faktur.id_gudang_stockist =
        this.getOrder.data.attributes.destination_warehouse_id;
      this.dataPenjualan.faktur.kode_stockist = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.area_stockist = parseInt(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships['area'].data.id
        ).attributes.code
      );
      this.dataPenjualan.faktur.nama_pemilik = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      ).attributes.name;
      this.dataPenjualan.faktur.id_gudang = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-warehouse'].data.id
      ).id;
      this.dataPenjualan.faktur.kode_gudang = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-warehouse'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.area_gudang = parseInt(
        this.getRelationships(
          this.getOrder,
          this.getOrder.data.relationships['origin-warehouse'].data.id
        ).attributes.area_code
      );
      this.dataPenjualan.faktur.id_wilayah_harga = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['area'].data.id
      ).id;
      this.dataPenjualan.faktur.wilayah_harga = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['area'].data.id
      ).attributes.code;
      this.dataPenjualan.faktur.address = this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      ).attributes.address;
      this.dataPenjualan.faktur.is_minus_bv =
        this.getOrder.data.attributes.is_minus_bv;
      this.dataPenjualan.faktur.discount =
        this.getOrder.data.attributes.discount_price;
      this.dataPenjualan.faktur.metode =
        this.getOrder.data.attributes.order_shipment;
      this.dataPenjualan.faktur.notes = this.getOrder.data.attributes.notes;

      if (withOrderDetails) {
        this.getOrder.data.relationships['order-details'].data
          .map((relationship) =>
            this.getRelationships(this.getOrder, relationship.id)
          )
          .map(async function (item, index) {
            this.setOrderDetail(index, item);
          }, this);
      }

      if (withPayments && order.data.attributes.is_using_stock) {
        const payments = await this.fetchPaymentPaymentMethods({
          id: this.getOrder.data.relationships['payments'].data[0].id,
          include: 'office-bank',
        });

        this.setPaymentDetails(payments.data.data);
      }
    },
    setPaymentDetails: function () {
      this.paymentMethods = this.getPaymentMethods.data
        .filter(
          (paymentMethod) => paymentMethod.relationships['office-bank'].data
        )
        .map((paymentMethod) => {
          const bank = this.getSingleIncluded(
            this.getPaymentMethods,
            paymentMethod.relationships['office-bank'].data.id
          );

          return {
            id: paymentMethod.id,
            type: this.paymentMethodTypes.find(
              (type) =>
                type.name === paymentMethod.attributes.payment_method_type
            ),
            bank: {
              id: bank.id,
              name: bank.attributes.name,
            },
            value: paymentMethod.attributes.payment_amount,
            receipt_number: paymentMethod.attributes.receipt_number,
          };
        });

      if (!this.paymentMethods.length && this.paymentAmountRemainder > 0) {
        this.pushEmptyPaymentDetailsItem();
      }
    },
    pushEmptyPaymentDetailsItem: function () {
      this.paymentMethods.push({
        type: null,
        value: null,
        bank: null,
      });
    },
    async setBuyerTypes() {
      const res = await this.fetchBuyerTypes();

      this.tipeHarga = res.data.data.filter((it) =>
        this.tipeHarga.includes(it.attributes.name)
      );

      return res;
    },
    async setWarehouse() {
      this.setLoading('Loading Gudang');

      await this.fetchWarehouses({
        'filter[code]': 'NASA',
      });

      if (this.getWarehouses.data.length > 0) {
        const warehouse = this.getWarehouses.data[0];
        this.dataPenjualan.faktur.id_gudang = warehouse.id;
        this.dataPenjualan.faktur.kode_gudang = `${warehouse.attributes.code} (${warehouse.attributes.area_code})`;
        this.dataPenjualan.faktur.nama_gudang = warehouse.attributes.name;
        this.dataPenjualan.faktur.area_gudang = warehouse.attributes.area_code;
        this.updateArea();
      }
    },
    async setOfficeBank() {
      const res = await this.fetchBanksByOffice({
        office_id: StorageService.getUser().office_id,
      });

      if (res) {
        this.officeBanks = res.data.data;
      }
    },
    setSetupsForMinOrder: async function () {
      this.fetchSetups({
        'filter[setup_key]': 'min_order_spend_for_stockist',
      });
    },
    async setOffice() {
      const response = await this.fetchOfficeById({
        office_id: StorageService.getUser().office_id,
      });

      this.sellerOfficeCode = response.data.data.attributes.code;
    },
    async blurOfficeCode() {
      if (this.dataPenjualan.faktur.kode_stockist) {
        this.setLoading('Memilih Stockist');

        const offices = await this.fetchOfficesByCategory({
          category: 'Stockist',
          params: {
            'filter[code]':
              this.dataPenjualan.faktur.kode_stockist.split(' ')[0],
            'fields[offices]':
              'name,code,area,addresses,phone,balance,order_balance_available,order_balance,pending_order_spent',
            'fields[areas]': 'code',
            include: 'area',
            'pagination[type]': 'cursor',
            'page[limit]': 1,
          },
        });

        if (offices.data.length) {
          const stockist = offices.data[0];
          const area = this.getSingleIncluded(
            offices,
            stockist.relationships.area.data.id
          );

          this.dataPenjualan.faktur.stockist = stockist;
          this.dataPenjualan.faktur.id_stockist = stockist.id;
          this.dataPenjualan.faktur.nama_pemilik = stockist.attributes.name;
          this.dataPenjualan.faktur.kode_stockist = `${stockist.attributes.code} (${area.attributes.code})`;
          this.dataPenjualan.faktur.area_stockist = area.attributes.code;
          this.dataPenjualan.faktur.address = stockist.attributes.address;
          this.dataPenjualan.faktur.phone = stockist.attributes.phone;
          this.dataPenjualan.faktur.is_minus_bv = false;
          this.dataPenjualan.faktur.saldo_awal = stockist.attributes.balance;
          this.dataPenjualan.faktur.saldo_order_awal =
            stockist.attributes.order_balance_available;

          this.setLoading('Loading Gudang Stockist');
          this.fetchWarehouseByOffice({
            userOfficeId: this.dataPenjualan.faktur.id_stockist,
            notCode: 'nasa',
          }).then((response) => {
            this.dataPenjualan.faktur.gudang_stockist = response.data;
            this.dataPenjualan.faktur.id_gudang_stockist =
              response.data.data[0].id;
          });

          // [FE] Penjualan ke stockist selain Stockist, HD, HK maka hanya bisa diambil tidak bisa di kirim
          this.dataPenjualan.faktur.metode = 'pickup';

          this.updateArea();
          if (this.checkSelectedStockistType('KYN')) {
            const buyerType = this.tipeHarga.find(
              (type) => type.attributes.name === 'Karyawan'
            );

            this.dataPenjualan.faktur.buyer_type = buyerType;
            this.dataPenjualan.faktur.id_buyer_type = buyerType.id;
            this.dataPenjualan.faktur.kode_buyer_type =
              buyerType.attributes.code;
          } else if (this.checkSelectedStockistType('PRSH')) {
            const buyerType = this.tipeHarga.find(
              (type) => type.attributes.name === 'Free'
            );

            this.dataPenjualan.faktur.buyer_type = buyerType;
            this.dataPenjualan.faktur.id_buyer_type = buyerType.id;
            this.dataPenjualan.faktur.kode_buyer_type =
              buyerType.attributes.code;
            this.dataPenjualan.faktur.metode = 'pickup';
          } else {
            const buyerType = this.tipeHarga.find(
              (type) => type.attributes.name === 'Stockist'
            );

            this.dataPenjualan.faktur.buyer_type = buyerType;
            this.dataPenjualan.faktur.id_buyer_type = buyerType.id;
            this.dataPenjualan.faktur.kode_buyer_type =
              buyerType.attributes.code;
          }
        } else {
          this.dataPenjualan.faktur.id_stockist = null;
          this.dataPenjualan.faktur.nama_pemilik = null;
          this.dataPenjualan.faktur.wilayah_harga = null;
          this.dataPenjualan.faktur.address = null;
          this.dataPenjualan.faktur.city = null;
          this.dataPenjualan.faktur.phone = null;
        }
      }
    },
    handleChangeGudang() {
      if (this.dataPenjualan.faktur.kode_gudang) {
        this.setLoading('Memilih Gudang');

        this.fetchWarehouses({
          keyword: this.dataPenjualan.faktur.kode_gudang.split(' ')[0],
          warehouseType: 'pusat,kantor_pelayanan',
        }).then(() => {
          if (this.getWarehouses.data.length > 0) {
            const warehouse = this.getWarehouses.data[0];
            this.dataPenjualan.faktur.id_gudang = warehouse.id;
            this.dataPenjualan.faktur.kode_gudang = `${warehouse.attributes.code} (${warehouse.attributes.area_code})`;
            this.dataPenjualan.faktur.nama_gudang = warehouse.attributes.name;
            this.dataPenjualan.faktur.area_gudang =
              warehouse.attributes.area_code;
            this.updateArea();
          } else {
            this.dataPenjualan.faktur.id_gudang = null;
            this.dataPenjualan.faktur.kode_gudang = null;
            this.dataPenjualan.faktur.nama_gudang = null;
            this.dataPenjualan.faktur.area_gudang = null;
          }
        });
      }
    },
    changeTotal(index) {
      this.dataPenjualan.barang[index].jumlah = event.target.value;
    },
    handleInputProductQty(index) {
      // this.dataPenjualan.barang[index].productQty = event.target.value;
      this.dataPenjualan.barang[index].total_harga =
        this.dataPenjualan.barang[index].productQty *
        this.dataPenjualan.barang[index].harga;
      this.dataPenjualan.barang[index].total_berat =
        this.dataPenjualan.barang[index].productQty *
        this.dataPenjualan.barang[index].berat;

      // grabd total harga
      this.dataPenjualan.barang[index].grand_total_harga =
        this.dataPenjualan.barang[index].total_harga;
      this.countGrandTotal(index);
    },
    getGrandTotalWeight() {
      return this.dataPenjualan.barang.reduce(function (total, attributes) {
        return total + attributes.total_berat;
      }, 0);
    },
    getGrandTotalPV() {
      return this.dataPenjualan.barang.reduce(function (total, attributes) {
        return total + (attributes.point * attributes.productQty || 0);
      }, 0);
    },
    getGrandTotalBV() {
      return this.dataPenjualan.barang.reduce(function (total, attributes) {
        return total + (attributes.bonus * attributes.productQty || 0);
      }, 0);
    },
    async handleChangeProductQty(index) {
      // jika jumlah yang dimasukkan kurang dari 0
      if (
        this.dataPenjualan.barang[index].productQty < 0 ||
        isNaN(this.dataPenjualan.barang[index].productQty)
      ) {
        this.dataPenjualan.barang[index].productQty = 0;

        this.createAlert({
          data: 'Jumlah harus bilangan bulat dan tidak boleh kurang dari 0',
          type: 'error',
        });
      } else {
        this.setLoadingProduct(true, 'Menyimpan Barang');

        // update order details
        const orderDetail = await this.updateOrderDetail({
          orderDetailId: this.dataPenjualan.barang[index].orderDetailId,
          productId: this.dataPenjualan.barang[index].productId,
          productQty: this.dataPenjualan.barang[index].productQty,
        });
        const isLastRow = this.dataPenjualan.barang.length - 1 === index;

        if (!orderDetail) {
          this.handleUpdateProductError(index);
        } else {
          this.loadProductBundles(this.$route.params.id);
          await this.setOrderDetail(index, orderDetail.data.data, {
            pushEmptyProduct: false,
          });

          this.error = null;
        }

        if (isLastRow) {
          this.pushEmptyProduct();
        }

        const codeEl = document.querySelectorAll('input[name=kode_barang]');

        codeEl[codeEl.length - 1].focus();

        this.countGrandTotal(index);
        this.setLoadingProduct(false);
      }

      if (!this.paymentMethods.length && this.paymentAmountRemainder > 0) {
        this.pushEmptyPaymentDetailsItem();
      }
    },
    handleConfirm() {
      this.addStatus = !this.addStatus;
    },
    handleSave() {
      this.setLoading('Menyimpan Order');

      this.validate({
        id: this.dataPenjualan.faktur.id,
        data: {
          selected_bundles_id: this.selectedBundles,
          choosen_bonuses: this.choosenBonuses
        }
      }).then((response) => {
        if (response) {
          this.setLoading('Loading Order');

          this.fetchOrder({
            id: this.getOrder.data.id,
            ...fetchDetailOrderParams,
          }).then(() => {
            this.addStatus = false;
            this.success = true;
          });
        } else {
          this.failed = true;
        }
      });
    },
    async createPaymentMethods({ orderResponse }) {
      if (orderResponse) {
        // Create Payment Methods
        const batchRequests = this.metodePembayaran
          .filter((method) => method.jenisPembayaran)
          .map((method) => {
            const officeBankByType = this.officeBanks.filter(
              (officeBank) =>
                officeBank.attributes.office_bank_type ===
                method.jenisPembayaran.value
            );
            return this.createPaymentMethod({
              paymentId:
                orderResponse.data.data.relationships.payments.data[0].id,
              officeBankId: method.officeBank
                ? method.officeBank.id
                : officeBankByType[0]?.id ?? null,
              attributes: {
                payment_amount: method.nominal ?? 0,
                transfer_date: method.transfer_date
                  ? dayjs(method.transfer_date).format()
                  : null,
                ...(method.jenisPembayaran.value === 'stockist'
                  ? { balance_used: 'stockist' }
                  : {}),
                ...(method.value === 'edc' ? { balance_used: 'edc' } : {}),
              },
            });
          });

        return await Promise.all(batchRequests);
      }
    },
    getRelationships(resource, id) {
      let data = resource?.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    getRelationshipsResources(resource, relationships) {
      let data = resource.included.filter(function (el) {
        return relationships
          .map((relationship) => relationship.id)
          .includes(el.id);
      });
      return data;
    },
    async handleRemoveProduct(index) {
      this.setLoadingProduct(true, 'Menghapus Barang');

      const orderDetailId = this.dataPenjualan.barang[index].orderDetailId;

      await this.removeOrderDetail({ orderDetailId });
      this.loadProductBundles(this.$route.params.id);
      this.dataPenjualan.barang.splice(index, 1);

      if (!this.dataPenjualan.barang.length) {
        this.pushEmptyProduct();
      }

      this.setLoadingProduct(false);
    },
    async handleChangeProduct(index) {
      if (this.dataPenjualan.barang[index].searchCode) {
        this.setLoadingProduct(true, 'Menyimpan Barang');

        const orderDetailId = this.dataPenjualan.barang[index].orderDetailId;

        const product = await this.getStockProductByCode(
          this.dataPenjualan.barang[index].searchCode
        );

        if (product) {
          const orderDetail = await (orderDetailId
            ? this.updateOrderDetail({
                orderDetailId,
                productId: product.id,
                productQty: 0,
              })
            : this.storeOrderDetail({
                productId: product.id,
                productQty: 0,
              }));

          if (!orderDetail) {
            this.handleStoreProductNotFund(index);
          } else {
            this.loadProductBundles(this.$route.params.id);
            this.setOrderDetail(index, orderDetail.data.data, {
              pushEmptyProduct: false,
              focusProductQty: true,
              withProductQty: false,
            });
            this.countGrandTotal(index);

            this.error = null;
          }
        } else {
          this.handleStoreProductNotFund(index);
        }

        if (!this.paymentMethods.length && this.paymentAmountRemainder > 0) {
          this.pushEmptyPaymentDetailsItem();
        }

        this.setLoadingProduct(false);
      }
    },
    handleStoreProductNotFund(index) {
      if (this.dataPenjualan.barang[index].kode_barang) {
        this.dataPenjualan.barang[index].searchCode =
          this.dataPenjualan.barang[index].kode_barang;
      } else {
        this.dataPenjualan.barang[index].searchCode = null;
      }
    },
    handleUpdateProductError(index) {
      this.dataPenjualan.barang[index].productQty =
        this.dataPenjualan.barang[index].stock;
    },
    handleClose: function () {
      this.$router.push('/penjualan/transaksi-penjualan');
    },
    handleEditOrder: function (id) {
      this.$router.push(`/penjualan/transaksi-penjualan/${id}`).then(() => {
        this.resetOrder();
        this.resetPayment();
        this.resetBarang();

        this.setOfficeBank();

        this.loadOrder(id).then((response) => {
          this.setOrder(response.data);

          this.success = false;
        });
      });
    },
    handleChangeTipeHarga() {
      this.dataPenjualan.faktur.is_minus_bv = false;

      if (
        this.tipeHargaKaryawan.includes(
          this.dataPenjualan.faktur.buyer_type?.attributes.name
        )
      ) {
        this.dataPenjualan.faktur.wilayah_harga =
          this.dataPenjualan.faktur.buyer_type?.attributes.code;
        this.changeArea();
      } else {
        this.dataPenjualan.faktur.wilayah_harga = 0;
        this.changeArea();
      }

      this.dataPenjualan.faktur.kode_buyer_type =
        this.dataPenjualan.faktur.buyer_type?.attributes.code;
      this.resetBarang();

      this.dataPenjualan.faktur.metode = 'pickup';
    },
    resetOrder() {
      this.dataPenjualan = {
        faktur: {
          id: null,
          no_invoice: null,
          tanggal: null,
          id_stockist: null,
          id_gudang_stockist: null,
          kode_stockist: null,
          nama_pemilik: null,
          kode_gudang: null,
          wilayah_harga: null,
          address: null,
          city: null,
          phone: null,
          destination_address: {
            province: {
              id: '',
              name: '',
            },
            city: {
              id: '',
              name: '',
              area: '',
            },
            district: {
              id: '',
              name: '',
            },
            village: {
              id: '',
              name: '',
            },
            alamat: null,
            status: false,
          },
        },
        barang: [
          {
            id_barang: null,
            kode_barang: null,
            nama_barang: null,
            stock: null,
            status: true,
            is_point: null,
            jumlah: null,
            harga: null,
            berat: null,
            total_harga: null,
            total_berat: null,
            searchCode: null,
            orderDetailId: null,
            productId: null,
            productQty: null,
          },
        ],
      };
    },
    resetPayment() {
      this.metodePembayaran = [];
    },
    resetBarang() {
      this.dataPenjualan.barang = [
        {
          id_barang: null,
          kode_barang: null,
          nama_barang: null,
          stock: null,
          status: true,
          is_point: null,
          jumlah: null,
          harga: null,
          berat: null,
          total_harga: null,
          total_berat: null,
          discount: 0,
          grand_total_harga: 0,
          point: 0,
          bonus: 0,
          searchCode: null,
          orderDetailId: null,
          productId: null,
          productQty: null,
        },
      ];
    },
    setOrderDetail(index, item, options = {}) {
      this.dataPenjualan.barang[index].berat = item.attributes.product_weight;
      this.dataPenjualan.barang[index].bonus = item.attributes.bonus_value;
      this.dataPenjualan.barang[index].data = {
        data: [{ attributes: item.attributes }],
      };
      this.dataPenjualan.barang[index].grand_total_harga =
        item.attributes.total_price;
      this.dataPenjualan.barang[index].harga = item.attributes.product_price;
      this.dataPenjualan.barang[index].id_barang = item.attributes.product_id;
      this.dataPenjualan.barang[index].is_point = item.attributes.is_point;
      this.dataPenjualan.barang[index].productQty =
        options.withProductQty ?? true ? item.attributes.product_qty : null;
      this.dataPenjualan.barang[index].kode_barang =
        item.attributes.product_code;
      this.dataPenjualan.barang[index].nama_barang =
        item.attributes.product_name;
      this.dataPenjualan.barang[index].point = item.attributes.point_value;
      this.dataPenjualan.barang[index].status = true;
      // stok produk = current stok + jumlah barang yang dibeli
      this.dataPenjualan.barang[index].stock =
        item.attributes.current_stock + item.attributes.product_qty;
      this.dataPenjualan.barang[index].total_berat = parseFloat(
        item.attributes.total_weight
      );
      this.dataPenjualan.barang[index].total_harga =
        item.attributes.product_price;
      this.dataPenjualan.barang[index].searchCode =
        item.attributes.product_code;
      this.dataPenjualan.barang[index].productId = item.attributes.product_id;
      this.dataPenjualan.barang[index].orderDetailId = item.id;

      if (options.pushEmptyProduct ?? true) {
        this.pushEmptyProduct();
      }

      if (options.focusProductQty ?? false) {
        setTimeout(() => {
          this.$refs.jumlah_barang[index].focus();
        }, 0);
      }
    },
    async getStockProductByCode(code) {
      this.setLoading('Memilih Produk');

      const stocks = await this.fetchWarehouseStock({
        isMinusBV: this.dataPenjualan.faktur.is_minus_bv,
        warehouseId: this.dataPenjualan.faktur.id_gudang,
        sellerOfficeCode: this.sellerOfficeCode,
        buyerOfficeCode: this.dataPenjualan.faktur.kode_stockist.split(' ')[0],
        productCode: code,
        areaCode: this.areaCode,
        buyerTypeCode: this.dataPenjualan.faktur.kode_buyer_type,
      });

      if (stocks?.data?.data?.length) {
        return this.getRelationships(
          stocks.data,
          stocks.data?.data[0].relationships.product.data.id
        );
      } else {
        this.createAlert({ data: 'Produk tidak ditemukan', type: 'warning' });
      }
    },
    pushEmptyProduct() {
      const barang = {
        id_barang: null,
        kode_barang: null,
        nama_barang: null,
        stock: null,
        status: true,
        is_point: null,
        jumlah: null,
        harga: null,
        berat: null,
        total_harga: null,
        total_berat: null,
        searchCode: null,
        orderDetailId: null,
        productId: null,
        productQty: null,
      };
      this.dataPenjualan.barang.push(barang);
      this.dataPenjualan.total_barang++;
    },
    async storeOrder() {
      const payload = {
        data: {
          type: 'orders',
          attributes: {
            order_type: 'sale',
            notes: this.dataPenjualan.faktur.notes,
            is_ppn_included: true,
            is_pre_order: false,
            order_shipment: this.dataPenjualan.faktur.metode,
            origin_warehouse_id: this.dataPenjualan.faktur.id_gudang,
            destination_warehouse_id:
              this.dataPenjualan.faktur.id_gudang_stockist,
            origin_office_id: StorageService.getUser().office_id,
            destination_office_id: this.dataPenjualan.faktur.id_stockist,
            is_minus_bv: this.dataPenjualan.faktur.is_minus_bv,
            discount_price: parseFloat(this.dataPenjualan.faktur.discount),
            destination_address: this.usingCustomAddress
              ? {
                  province_id:
                    this.order.relationships.destinationAddress.attributes
                      .province.id,
                  city_id:
                    this.order.relationships.destinationAddress.attributes.city
                      .id,
                  district_id:
                    this.order.relationships.destinationAddress.attributes
                      .district.id,
                  village_id:
                    this.order.relationships.destinationAddress.attributes
                      .village.id,
                  address:
                    this.order.relationships.destinationAddress.attributes
                      .alamat,
                }
              : undefined,
            is_using_stock: this.form.is_using_stock,
          },
          relationships: {
            'buyer-type': {
              data: {
                type: 'buyer-types',
                id: this.dataPenjualan.faktur.buyer_type.id,
              },
            },
            area: {
              data: {
                type: 'areas',
                id: this.dataPenjualan.faktur.id_wilayah_harga,
              },
            },
          },
        },
      };

      this.setLoading('Menyimpan Order');
      const order = await this.createOrderWithParams({
        payload,
        params: {
          include:
            'destination-office,origin-office,buyer-type,area,origin-warehouse,destination-warehouse,payments',
        },
      });

      if (order) {
        this.dataPenjualan.faktur.id = order.data.data.id;
        this.is_detail = true;

        await this.$router.push(
          `/penjualan/transaksi-penjualan/${order.data.data.id}`
        );

        this.setOrder({ withOrderDetails: false, withPayments: false });
      }
    },
    async storeOrderDetail({ productId, productQty }) {
      if (!this.dataPenjualan.faktur.id) {
        await this.storeOrder();

        this.createAlert({
          data: `Faktur ${this.dataPenjualan.faktur.no_invoice} Tersimpan`,
        });
      }

      const orderDetail = await this.createOrderDetail({
        payload: {
          data: {
            type: 'order-details',
            attributes: {
              product_qty: parseInt(productQty),
            },
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.dataPenjualan.faktur.id,
                },
              },
              product: {
                data: {
                  type: 'products',
                  id: productId,
                },
              },
            },
          },
        },
      });

      return orderDetail;
    },
    async updateOrderDetail({ orderDetailId, productId, productQty }) {
      const orderDetail = await this.patchOrderDetail({
        id: orderDetailId,
        payload: {
          data: {
            type: 'order-details',
            id: orderDetailId,
            attributes: {
              product_qty: parseInt(productQty),
            },
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: productId,
                },
              },
            },
          },
        },
      });

      if (orderDetail) {
        this.notifyCashback();
      }

      return orderDetail;
    },
    async removeOrderDetail({ orderDetailId }) {
      return await this.deleteOrderDetail({
        id: orderDetailId,
      });
    },
    setLoading(text) {
      this.loadingText = text;
    },
    notifyCashback() {
      if (this.cashback) {
        this.createAlert({
          data: `Berhasil mendapatkan cashback ${this.$options.filters.toCurrency(
            this.cashback
          )}`,
        });
      }
    },
    handleChangeAddress() {
      this.usingCustomAddress = true;

      // this.resetOrderDetail();
      // this.resetPaymentMethod();

      // this.setArea();
    },

    // promo
    onChangeSelectedBundles(value) {
      this.selectedBundles = [ ...value ]
    },
    onChangeChoosenBonuses(value) {
      this.choosenBonuses = { ...value }
    }
  },
};
</script>

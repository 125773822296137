<template>
  <div class="flex justify-end gap-x-2">
    <base-button :disabled="!canValidate" @click="onValidate"
      >Validasi</base-button
    >
    <router-link :to="backRoute">
      <base-button color="white">Kembali</base-button>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  emits: ['validated'],
  props: {
    data: {
      type: Object,
      required: true,
    },
    backRoute: null,
    withBalanceType: Boolean,
  },
  computed: {
    ...mapGetters({
      getRetur: 'returs/getRetur',
    }),
    canValidate() {
      if (this.withBalanceType && !this.data.balanceType) {
        return false;
      }

      return this.data.details.every((item) => {
        if (item.expired_count && !item.expired_product_price_id) {
          return false;
        }

        if (item.damaged_count && !item.damaged_product_price_id) {
          return false;
        }

        if (
          item.suitable_for_sale_count &&
          !item.suitable_for_sale_product_price_id
        ) {
          return false;
        }

        if (
          item.unsuitable_for_sale_count &&
          !item.unsuitable_for_sale_product_price_id
        ) {
          return false;
        }

        return true;
      });
    },
  },
  methods: {
    ...mapActions({
      setReturPrice: 'returs/setReturPrice',
    }),

    async onValidate() {
      const details = this.data.details.map((detail) => ({
        retur_detail_id: detail.id,
        expired_product_price_id: detail.expired_product_price_id,
        damaged_product_price_id: detail.damaged_product_price_id,
        suitable_for_sale_product_price_id:
          detail.suitable_for_sale_product_price_id,
        unsuitable_for_sale_product_price_id:
          detail.unsuitable_for_sale_product_price_id,
      }));

      const res = await this.setReturPrice({
        id: this.getRetur.data.id,
        payload: {
          data: {
            attributes: {
              details,
              balance_type: this.withBalanceType ? this.data.balanceType : null,
            },
          },
        },
      });

      if (res) {
        this.$emit('validated');
      }
    },
  },
};
</script>

<template>
  <div class="flex justify-end gap-x-2">
    <base-select
      :shadow="false"
      :expand="false"
      :options="filterStatusOptions"
      v-model="filter.status"
      v-on:change="onChange"
    />
    <base-search
      placeholder="Cari Kode"
      class="h-10"
      v-model="inputSearch"
      v-on:input="onSearch"
    />
    <base-button :to="createRoute" type="button" id="tambah_transaksi">
      <!-- Heroicon name: solid/mail -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
          clip-rule="evenodd"
        />
      </svg>
      Tambah Retur
    </base-button>
  </div>
</template>
<script>
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'ReturHeader',
  components: {
    BaseSearch,
    BaseButton,
  },
  props: {
    createRoute: [Object, String],
    value: String,
  },
  emits: ['input', 'filter-status'],
  data() {
    return {
      filter: {
        status: null
      }
    }
  },
  computed: {
    inputSearch: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    filterStatusOptions() {
      return [
        {
          key: 'status',
          value: null,
          label: 'Status'
        },
        ...['draft', 'diproses_gudang', 'dikirim', 'valid_saldo', 'selesai'].map(item => ({
          key: item,
          value: item,
          label: item.split('_').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join(' ')
        }))
      ]
    }
  },
  methods: {
    onSearch(value) {
      this.$emit('search', value);
    },
    onChange() {
      this.$emit('filter-status', this.filter.status)
    }
  },
};
</script>

<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <LaporanHeader />

      <section class="mt-10">
        <div
          class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
        >
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Validasi Laporan Penjualan KP
          </h3>
        </div>
        <div class="flex flex-col">
          <div class="flex my-4 items-end justify-end space-x-2">
            <div class="relative rounded-md shadow-sm">
              <label for="from">Laporan</label>
              <select
                v-model="is_validated"
                @change="filterByValidated"
                class="flex mt-1 appearance-none justify-center rounded-md border border-gray-300 bg-white py-2 pl-4 pr-10 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Laporan Manual</option>
                <option :value="false">Laporan Otomatis</option>
              </select>
            </div>

            <div class="relative rounded-md shadow-sm">
              <label for="from">Status Validasi</label>
              <select
                v-model="is_validated"
                @change="filterByValidated"
                class="flex mt-1 appearance-none justify-center rounded-md border border-gray-300 bg-white py-2 pl-4 pr-10 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Tervalidasi</option>
                <option :value="false">Belum Tervalidasi</option>
              </select>
            </div>
            <!-- Periode -->
            <div>
              <label for="from">Periode</label>
              <base-select
                :options="periodOptions"
                class="mt-1 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                v-model="selectedPeriod"
                @change="handleChangePeriod"
              />
            </div>
            <!-- filter date -->
            <template v-if="selectedPeriod !== null">
              <!-- Start From -->
              <div>
                <label for="from">Dari</label>
                <div class="flex sm:flex-row sm:gap-x-4">
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input
                      @change="onChangeDate"
                      v-model="startDate"
                      type="date"
                      name="filter"
                      id="from"
                      class="focus:outline-none block w-full rounded-md border-gray-300 py-2 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <!-- End From -->
              <div>
                <label for="to">Sampai</label>
                <div class="flex sm:flex-row sm:gap-x-4">
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input
                      @change="onChangeDate"
                      v-model="endDate"
                      type="date"
                      name="filter"
                      id="to"
                      class="focus:outline-none block w-full rounded-md border-gray-300 py-2 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <!-- end filter -->
            </template>
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="-flex sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
              <Datatable
                :total="getOrderBundles.meta.page.total"
                :perPage="getOrderBundles.meta.page.perPage"
                :currentPage="getOrderBundles.meta.page.currentPage"
                @pagechanged="onPageChange"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nama Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Deskripsi
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Tanggal Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <div v-if="isLoading" class="flex items-center text-center">
                    <loading></loading>
                  </div>
                  <tbody v-if="getOrderBundles.data?.length > 0">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrderBundles.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.name }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.description }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.is_validated"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Tervalidasi
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                        >
                          Belum Tervalidasi
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="5"
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        Tidak ada data
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </section>

      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div class="flex items-center space-x-4">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Detail Laporan
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Data laporan yang telah dibuat
                    </p>
                  </div>
                  <router-link
                    v-if="!dataLaporan.attributes.is_submitted"
                    :to="'restock/' + dataLaporan.id"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 h-6 w-6 cursor-pointer hover:text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </router-link>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">No Laporan</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Tanggal Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataLaporan.attributes.updatedAt).format('ll LT')
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Validasi</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataLaporan.attributes.is_validated"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Valid
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Belum tervalidasi
                      </span>
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Nama Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.name }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Deskripsi Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.description }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Dikirim</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataLaporan.attributes.is_submitted"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Terkirim ke Pusat
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                      >
                        Belum Terkirim
                      </span>
                    </dd>
                  </div>
                </dl>
                <!-- {{getRelationships(getOrderBundles, dataLaporan}} -->
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">
                    Daftar Restock
                  </dt>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nomor PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Tanggal PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Produk
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Berat
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Harga
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(data, index) in dataLaporan.relationships[
                            'orders'
                          ].data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.origin_code
                            }}
                            <br />
                            <span class="text-xs">
                              {{
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.destination_code
                              }}
                            </span>
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              dayjs(
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.updatedAt
                              ).format('ll LT')
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.item_count
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.grand_total_weight
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.final_price | toCurrency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah Pembayaran
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Aksi
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody
                        v-if="
                          dataLaporan.relationships.paymentBundles.data
                            ?.length > 0
                        "
                      >
                        <tr
                          class="bg-white"
                          v-for="(data, index) in dataLaporan.relationships
                            .paymentBundles.data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                ?.attributes.payment_amount | toCurrency
                            }}
                          </td>
                          <td class="px-6">
                            <span
                              v-if="
                                getRelationships(getOrderBundles, data.id)
                                  ?.attributes.is_verified
                              "
                              class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                            >
                              Terverifikasi
                            </span>
                            <span
                              v-else
                              class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                            >
                              Belum Terverifikasi
                            </span>
                          </td>
                          <td class="px-6">
                            <button
                              @click="verifyPaymentBundles(data.id)"
                              v-if="
                                !getRelationships(getOrderBundles, data.id)
                                  ?.attributes.is_verified
                              "
                              type="button"
                              class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                              Validasi
                            </button>
                            <button
                              v-else
                              type="button"
                              class="focus:outline-none inline-flex cursor-not-allowed items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white opacity-50 shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                              Tervalidasi
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="bg-white">
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                            colspan="8"
                          >
                            Data tidak ditemukan
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
              </div>
              <div class="flex justify-end gap-2">
                <button
                  @click="handlePeriodModal"
                  v-if="!dataLaporan.attributes.is_validated"
                  type="button"
                  class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Validasi
                </button>

                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Modal
      :showing="openPeriodModal"
      @close="handlePeriodModal"
      :showClose="openPeriodModal"
      :backgroundClose="true"
      size="max-w-lg"
    >
      <h1 class="my-2 font-semibold">Pilih Periode</h1>
      <div class="flex mb-4 justify-between gap-4">
        <div class="field-inset-default w-full">
          <label for="month" class="block text-xs font-bold text-gray-700">
            Bulan
          </label>
          <select
            v-model="filter.month"
            name="month"
            class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
          >
            <option :value="''">Pilih Bulan</option>
            <option
              :value="month"
              v-for="month in months.slice(0, dayjs().month() + 1)"
              :key="month"
            >
              {{ month }}
            </option>
          </select>
        </div>
        <div class="field-inset-default w-full">
          <label for="year" class="block text-xs font-bold text-gray-700">
            Tahun
          </label>
          <select
            v-model="filter.year"
            name="year"
            class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
          >
            <option :value="''">Pilih Tahun</option>
            <option
              :value="year"
              v-for="year in [
                ...new Set(getPeriods.data.map((item) => item.attributes.year)),
              ]"
              :key="year"
            >
              {{ year }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <div class="flex w-full justify-end">
        <button
          @click="
            () => (!filter.month || !filter.year ? null : validasi(dataLaporan))
          "
          :class="[
            !filter.month || !filter.year
              ? 'cursor-not-allowed opacity-50'
              : null,
          ]"
          class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          </svg>
          Pilih dan Validasi
        </button>
      </div>
    </Modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import dayjs from 'dayjs';
import BaseSelect from '@/components/base/BaseSelect.vue';
import {
  getCurrentMonthName,
  getMonthName,
  getMonthNameIndex,
} from '@/services/utils.service';
import LaporanHeader from '@/components/laporan/header.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    Modal,
    BaseSelect,
    LaporanHeader,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      is_validated: false,
      startDate: null,
      endDate: null,
      selectedPeriod: null,
      selectedPeriodBefore: null,
      openPeriodModal: false,
      openDetail: false,
      loading: false,
      dataLaporan: {},
      filter: {
        month: '',
        year: '',
      },
      months: [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ],
    };
  },

  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
      isLoading: 'order_bundles/getLoading',
      getPeriods: 'periods/getPeriods',
    }),
    periodOptions: function () {
      return [
        {
          key: null,
          label: 'Semua',
          value: null,
        },
        ...this.getPeriods.data.map((period) => ({
          key: period.id,
          label: period.attributes.name,
          value: period,
        })),
      ];
    },
    orderBundlesParams: function () {
      return {
        'fields[order-bundles]':
          'orders,paymentBundles,has_debt,code,name,description,createdAt,is_submitted,is_validated',
        'fields[orders]':
          'origin_code,destination_code,updatedAt,item_count,grand_total_weight,final_price',
        include: 'orders,paymentBundles',
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrderBundles: 'order_bundles/fetchOrderBundles',
      updateOrderBundle: 'order_bundles/updateOrderBundle',
      updatePaymentBundles: 'payment_bundles/updatePaymentBundles',
      fetchPeriods: 'periods/fetchPeriods',
    }),
    verifyPaymentBundles(idPaymentBundles) {
      const payload = {
        data: {
          type: 'payment-bundles',
          id: idPaymentBundles,
          attributes: {
            is_verified: true,
          },
        },
      };
      this.updatePaymentBundles({ payload, id: idPaymentBundles }).then(() => {
        this.fetchOrderBundles({
          pageNumber: 1,
          pageSize: 5,
          is_submitted: true,
          is_validated: this.is_validated,
          officeType: 'kantor_pelayanan',
          ...this.orderBundlesParams,
        });
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        officeType: 'kantor_pelayanan',
        ...this.orderBundlesParams,
      });
    }, 300),
    handleChangePeriod() {
      this.setFromToDateFromPeriod();
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        fromDate: this.startDate,
        toDate: this.endDate,
        officeType: 'kantor_pelayanan',
        ...this.orderBundlesParams,
      });
    },
    onChangeDate(e) {
      if (e.target.id === 'from') {
        this.startDate = e.target.value;
      } else if (e.target.id === 'to') {
        this.endDate = e.target.value;
      }

      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        fromDate: this.startDate,
        toDate: this.endDate,
        officeType: 'kantor_pelayanan',
        ...this.orderBundlesParams,
      });
    },
    onPageChange(page) {
      this.fetchOrderBundles({
        pageNumber: page,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        officeType: 'kantor_pelayanan',
        ...this.orderBundlesParams,
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.dataLaporan = data;
    },
    filterByValidated() {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        officeType: 'kantor_pelayanan',
        ...this.orderBundlesParams,
      });
    },
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'order-bundles',
          id: data.id,
          attributes: {
            is_validated: true,
          },
          relationships: {
            period: {
              data: {
                type: 'periods',
                id: this.getPeriods.data.find(
                  (it) =>
                    it.attributes.year === this.filter.year &&
                    it.attributes.name === this.filter.month
                ).id,
              },
            },
          },
        },
      };

      try {
        this.updateOrderBundle(payload)
          .then((response) => {
            if (response) {
              this.openDetail = false;
              this.openPeriodModal = false;
              this.fetchOrderBundles({
                pageNumber: 1,
                pageSize: 5,
                is_submitted: true,
                is_validated: this.is_validated,
                officeType: 'kantor_pelayanan',
                ...this.orderBundlesParams,
              }).catch(() => (this.loading = false));
            }
          })
          .catch(() => (this.loading = false));
      } catch (error) {
        this.loading = false;
      }
    },
    handlePeriodModal() {
      this.openPeriodModal = !this.openPeriodModal;
    },
    // setFromToDateFromPeriod
    setFromToDateFromPeriod() {
      const beforeSelectedMonthName = this.selectedPeriod
        ? getMonthName(
            getMonthNameIndex(this.selectedPeriod.attributes.name) - 1
          )
        : null;

      this.selectedPeriodBefore = this.selectedPeriod
        ? this.periodOptions.find(
            (period) => period.label === beforeSelectedMonthName
          ).value
        : null;

      this.startDate = this.selectedPeriodBefore
        ? dayjs(this.selectedPeriodBefore.attributes.start_date).format(
            'YYYY-MM-DD'
          )
        : null;
      this.endDate = this.selectedPeriod
        ? dayjs(this.selectedPeriod.attributes.start_date).format('YYYY-MM-DD')
        : null;
    },
    // setSelectedPeriodFromCurrentPeriod
    setSelectedPeriodFromCurrentPeriod() {
      const currentPeriod = getCurrentMonthName();

      this.selectedPeriod = this.periodOptions.find(
        (period) => period.label === currentPeriod
      ).value;
      this.setFromToDateFromPeriod();
    },
  },
  created() {
    this.fetchPeriods().then(() => {
      this.setSelectedPeriodFromCurrentPeriod();
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        fromDate: this.startDate,
        toDate: this.endDate,
        officeType: 'kantor_pelayanan',
        ...this.orderBundlesParams,
      });
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>

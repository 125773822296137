<template>
  <base-select
    expand
    :shadow="false"
    :options="productPriceOptions"
    v-model="selected"
    @change="$emit('change', selected)"
  />
</template>

<script>
import BaseSelect from '@/components/base/BaseSelect.vue';
import { toCurrency } from '@/services/currency.service';

export default {
  components: {
    BaseSelect,
  },
  props: {
    productPrices: Array
  },
  emits: ['change'],
  data() {
    return {
      selected: null
    }
  },
  computed: {
    productPriceOptions() {
      return [
        {
          key: 'pilih',
          value: null,
          label: 'Pilih Harga'
        },
        ...this.productPrices.map(item => ({
          key: item.id,
          value: item,
          label: `${toCurrency(item.price)} (${item.buyerType}, ${item.area})`
        }))
      ]
    }
  }
};
</script>

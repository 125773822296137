<template>
  <Datatable
    :total="getOrders.meta.page.total"
    :perPage="getOrders.meta.page.perPage"
    :currentPage="getOrders.meta.page.currentPage"
    @pagechanged="onPageChangeOrder"
    :is-empty="!getOrders.data.length"
    :meta="getOrders.meta"
    id="table_order"
    cursor
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Nomor Faktur
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode Stockist/Kode Gudang
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Harga
          </th>
          <th
            scope="col"
            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status Laporan
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status Faktur
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            PRD
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody class="relative">
      <tbody v-if="getOrders.data.length">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getOrders.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click.prevent="onClickRow(data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            <order-code-column :value="data" />
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              getRelationships(
                getOrders,
                data.relationships?.['destination-office'].data?.id
              )?.attributes?.code
            }}
            /
            {{
              getRelationships(
                getOrders,
                data.relationships['origin-office'].data?.id
              )?.attributes?.code
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{
              getRelationships(getOrders, data.relationships['order'].data.id)
                .attributes.grand_total_price | toCurrency
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <span
              v-if="data.attributes.deletedAt"
              class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
            >
              Terhapus
            </span>
            <base-badge
              v-else-if="data.attributes.order_status == 'Terlaporkan'"
            >
              Selesai
            </base-badge>
            <base-badge v-else color="red"> Belum Selesai </base-badge>
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <base-badge v-if="data.attributes.deletedAt" color="red">
              Terhapus
            </base-badge>
            <base-badge
              v-else
              :color="
                data.attributes.order_status == 'Draft' ? 'yellow' : 'blue'
              "
            >
              {{ data.attributes.order_status }}
            </base-badge>
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
          >
            {{
              data.relationships?.period?.data
                ? formatDate(
                    getSingleIncluded(
                      getOrders,
                      data.relationships.period.data.id
                    ).attributes.start_date,
                    'MMYY'
                  )
                : '-'
            }}
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/utils/date';
import OrderCodeColumn from '@/components/order/order-code-column.vue';

export default {
  name: 'PenjualanTable',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    }),
  },
  components: {
    OrderCodeColumn,
  },
  methods: {
    formatDate,
    onClickRow(val) {
      this.$emit('click-row', val);
    },
    onPageChangeOrder(val) {
      this.$emit('page-change', val);
    },
    getRelationships(resource, id) {
      let data = resource.included?.find(function (el) {
        return el.id === id;
      });
      return data;
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
